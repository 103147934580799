//@media (min-width: 1200px){
//  .container {
//    max-width: 1170px;
//    width: 100%;
//  }
//}
@media (min-width: 992px){
//  .container {
//    max-width: 970px;
//    width: 100%;
//  }
//}
//@media (min-width: 768px){
//  .container {
//    max-width: 750px;
//    width: 100%;
//  }
}
.container {
  max-width: 1170px;
  width: 100%;
}
@media (max-width: 1600px){
  .navbar-default .plazart-megamenu ul > li > a{
    font-size: 11px;
  }

}
@media (max-width: 1440px){

  ul.tz_recent_posts .slick-prev{
    left: -84px;
  }
  ul.tz_recent_posts .slick-next {
    right: -84px;
  }
  .tz_recent_posts{
    .slick-prev, .slick-next{
      top: 74%;
    }
  }

}
@media (max-width: 1374px){
  .tz_maniva_meetup_title{
    margin-right: 200px;
  }
}
@media (max-width: 1366px){

  .wrapper-carousel .layout-z0 img{
    height: 100% !important;
  }
  .tz_maniva_meetup_title{
    margin-right: 189px;
  }
}
@media (max-width: 1365px){
  .tz_recent_posts{
    .slick-prev, .slick-next{
      display: none !important;
    }
  }
}
@media (max-width: 1330px){
  .tz_maniva_meetup_title{
       margin-right: 131px;
     }

}
@media (max-width: 1280px){

  #tz-introduce-video-wrapper{
    background-size: 100% 100%;
  }
  .wrapper-carousel .layout-z0 img{
    height: 100% !important;
  }
  .container {
    max-width: 1170px;
    width: 100%;
  }
  .tztwd-container .tweets_txt{
    padding: 0;
  }
  .tz_maniva_meetup_title{
    margin-right: 120px;
  }
  .wrapper-meetup-video .tz-row{
    padding: 75px 55px 81px 13% !important;
  }
  #jsn_form_2.jsn-uniform.jsn-master{
    padding: 98px 100px 95px;
    .jsn-bootstrap .jsn-form-content .control-group:last-child{
      margin-bottom: 42px !important;
    }
  }
  .TzInformation{
    .row{
      margin: auto;
    }
  }

  .hikashop_checkout_login_left_part,.hikashop_checkout_login_right_part{
    width: 50%;
  }
}
@media (max-width: 1158px) {
  .hikashop_product_carousel{
    .buttonprev,.buttonnext{
        display: none;
    }
    table{
      margin: 0 !important;
    }
    .hikashop_main_carousel_div{
      width: 100% !important;
       > ul{
         width: 100% !important;
         > li{
            width: 100% !important;
           > ul{
             > li{
               margin: 0 !important;
               width: 100% !important;
             }
           }
         }
       }
    }
  }
  .hikashop_child_image{
    max-width: 100%;
  }
  .layout-show .hikashop_product_right_part,.hikashop_product_left_part{
    width: 100%;
  }

  .tz_onsale{
    left: 4px;
  }
  .tz_of_stock{
    right: 4px;
  }
}
@media (max-width: 1100px){
  .tz_register_meetup{
    width: 100%;
  }
  .tz_register_meetup_pricing{
    margin: 0;
  }
}
@media (max-width: 1024px) {
  .wrapper-carousel .tz_content_width{
    padding: 29px 0 74px 25px !important;
  }
  .tz_maniva_meetup_title{
    margin-right: 0;
  }
  .tz_breadcrumb_shop{
    .tz_breadcrumb_link p,.lnk_berum{
      text-align: left;
      margin-bottom: 0px;
    }
  }
  .iffooter{
    .row{
      > div{
        margin-bottom: 20px;
        &:last-child{
          margin-bottom: 0;
        }
      }
    }
  }

}
@media (max-width: 1199px) {
  .item-blogtz{
    #content{
      padding-top: 50px !important;
    }
  }
  #jsn_form_2.jsn-uniform.jsn-master{
    padding: 99px 30px 62px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .plazart-megamenu ul.navbar-nav > li{
    padding: 0 10px;
  }
  .tz_maniva_meetup_title{
    margin-right: 70px;
  }
}
@media (max-width: 980px){
  .fluid-width-video-wrapper{
    top: 220px;
  }
  .easy-opener-close{
    top: 220px;
  }
  .jicons-none{
    margin-bottom: 0;
  }
  .tzSocial_bg_meetup_style2 ul{
    float: left;
  }

  .tz_wpadd{
    width: 33.33333333%;
    float: left;
    margin-bottom: 65px;
  }

  .slick-next{
    display: none!important;
  }
  ul.tz_recent_posts .slick-next{
    display: none !important;
  }
  body{
    overflow-x: hidden;
  }
  .breadcrumb{
    padding: 30px 0;
    .right_breadcrumbs{
      text-align: left;
    }
  }

  .lnk_berum{
    margin-bottom: 15px;
  }

  #tz-logo a{
    padding: 36px 0 35px 15px;
  }
  .tz_discounts h3{
    font-size: 18px;
  }
  .tz_maniva_meetup_title{
    margin-right: 35px
  }
  .meetup_btn{
    padding: 10px 25px;
  }
  .tz_meetup_positionv1 h3{
    font-size: 14px
  }
  .tz-row-left {
    padding: 75px 49px 58px 30px !important;
    text-align: right;
  }
  .modal-lg {
    width: 900px;
  }
  .TzInformation .media-body .modal-header .close{
    float: left;
  }
  .tab-content{
    margin-left: 30px;
  }

  #jsn_form_2.jsn-uniform.jsn-master .jsn-bootstrap .jsn-form-content .control-group:last-child{
    margin-bottom: 78px!important;
  }
  .recen_box{
    img{
      width: 100%;
    }
  }
  .slick-dots{
    bottom: -56px;
  }
  .contactform .max-email-box{
    width: 43%;
  }
  .contactform:after{
    width: 25.0%;
  }
  .contactform:before{
    width: 25.0%;
  }
  .wrapper-carousel .tz_content_width{
    padding:29px 0 81px 30px !important;
  }
  .navbar-collapse{
    padding-right: 0;
    padding-left: 0;
  }
  .plazart-megamenu ul.navbar-nav > li{
    padding: 0 10px;
  }
  .modal-lg {
    width: 100%;
  }
  .tz_register_meetup_pricing{
    margin-right: 0;
    margin-left: 8px;
  }
}
@media (max-width: 930px){
  .top_right{
    width: 100%;
    .tzSocial_bg_meetup_style2{
      ul{
        li{
          float: left;
          &:first-child a{
            padding-right: 15px;
            padding-left: 0;
          }
        }
      }
    }

  }
}
@media (max-width: 991px){
  .top_left{
    display: none;
  }
}
@media (max-width: 842px){
.tz_maniva_meetup_title {
  margin-right: 0;
}
}
@media (max-width: 809px){
  .grid-item{
    width: 100% !important;
  }

}
@media (max-width: 800px){
  .plazart-megamenu ul.navbar-nav > li{
    padding: 0 6px;
  }
  .relative_item{
    margin: 0 15px;
  }
  .meetup_btn{
    padding: 10px 15px;
  }

  .conference-content ul li{
    margin: 0 5px 22px 0;
  }
  .conference-content ul:after,.conference-content .tz_tabs_meetup:after,.conference-content .tz_tabs_meetup:before{
    content: none !important;
  }
  .tz_register_meetup{
    width: 551px;
  }
  img.media-object{
    height: 100% !important;
  }
  .relative_footer .absolute_footer{
    right: 6em;
  }
  .tzSocial_bg_meetup_style2 ul li a{
    padding: 0 8px;
  }
  .navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus{
    background: @cl-orange;
  }
}
@media (max-width: 768px){
  .conference-content{
    .tz_meetup_btn{
      padding: 60px 0 0 0 !important;
      display: block;
      text-align: center;
    }
  }

  .tz-slider-text{
    p{
      font-size: 10px;
    }
  }
  img.media-object{
    top: 0 !important;
  }
  .wrapper-slideshows .tz-slider-text h4{
    font-size: 23px;
    margin-top: 10px;
  }
  .wrapper-slideshows .tz-counter .tz-coun{
    width: 56px;
  }
  .wrapper-slideshows .tz-counter input{
    font-size: 18px;
    height: 33px;
  }
  .wrapper-slideshows .tz-counter .tz-coun input {
    margin: 0;
  }
  .wrapper-slideshows .tz-counter span{
    font-size: 10px;
    margin: 0;
  }
  .wrapper-slideshows .tz-counter .tz-coun{
    margin: 0;
  }
  .wrapper-slideshows .tz_slider_meetup_btn li{
    margin: 0 8px;
  }
  .tz_wpadd .all-icadd span{
    float: none;
    font-size: 10px;
    margin: 0 auto;
    display: block;
  }
  .tz_wpadd .all-icadd h4{
    margin: 0;
    text-align: center;
    padding-top: 15px;
  }
  .tz_wpadd .cont-tt {
    padding: 0;
    text-align: center;
    padding-top: 30px;
  }

  body{
    .jsn-master .jsn-narrow .jsn-input-mini-fluid, .jsn-master .jsn-narrow .jsn-input-small-fluid, .jsn-master .jsn-narrow .jsn-input-medium-fluid, .jsn-master .jsn-narrow .jsn-input-xlarge-fluid, .jsn-master .jsn-narrow .jsn-input-fluid, .jsn-master .jsn-narrow .jsn-columns-container.jsn-columns-count-two .jsn-column-item, .jsn-master .jsn-narrow .jsn-columns-container.jsn-columns-count-three .jsn-column-item, .jsn-master .jsn-narrow .jsn-columns-container.jsn-columns-count-no .jsn-column-item, .jsn-master .jsn-bootstrap .jsn-narrow .input-small, .jsn-master .jsn-bootstrap .jsn-narrow .input-medium, .jsn-master .jsn-bootstrap .jsn-narrow .input-file, .jsn-master .jsn-bootstrap .jsn-narrow .jsn-column-item.jsn-uniform-others textarea.jsn-value-Others{
      width: 100% !important;
    }
  }

  #jsn_form_2.jsn-uniform.jsn-master {
    padding: 98px 40px 95px;
  }
  .vc_custom_jl{
    width: 100%;
  }
  .tz_register_meetup_pricing{
    margin-right: 10px;
  }
  .item-default .tz_register_meetup {
    width: 100%;
  }
  .hikashop_checkout_login_left_part,.hikashop_checkout_login_right_part{
    width: 100%;
  }
  .hikashop_checkout_login_left_part{
    margin-bottom: 25px;
  }
}
@media only screen and (max-width: 760px), (max-device-width: 1024px) and (min-device-width: 768px){
  #hikashop_checkout_cart table td:before{
    top: 0;
  }
  #hikashop_checkout_cart table td{
    text-align: left;
  }
  .hikashop_cart_product_name a{
    padding-left: 10px;
    span{
      display:  block;
      padding-left: 10px;
    }
  }
  .hikashop_cart_total_title.hikashop_cart_title{
    display: none !important;
  }
  #hikashop_checkout_cart td.hikashop_cart_product_name_value{
    width: 100%;
  }
  .hikashop_checkout_cart tr td.hikashop_cart_product_image_value{
    padding: 25px 10px !important;

  }
  .hikashop_checkout_cart tr td.hikashop_cart_product_name_value{
    padding-bottom: 0px !important;
  }
}
@media (max-width: 767px){
  .production_list{
    li{
      &:last-child{
        .hikashop_subcontainer{
          border-bottom: 0;
        }
      }
    }

      margin-bottom: 20px;

  }
  .masonry{
    padding: 30px 0;
  }

  .padding-tb-140px,.padding-tb-110{
    padding: 50px 0;
  }

  li.dropdown:hover .dropdown-menu{
    top: 0;
  }
  .dropdown-menu{
    top: 0;
  }
  .conference-content ul li{
    margin: 0 0 22px 0;
    a{
      padding: 6px 15px;
    }
  }
  body #tz-event-wrapper{
    padding: 85px 0 0 !important;
  }
  #conference{
    padding-top: 20px !important;
  }
  .service-list .tz_description{
    margin-bottom: 15px;
  }
  .jsn-form-submit.meetup_btn{
    float: none;
  }
  .tz_opacity{
    background-size: 100% 100%;
  }
  .wrapper-meetup-video{
    .meetup_btn {
      margin-top: 20px;
    }
    .tz-row{
      padding: 75px 15px 40px!important;
    }
    .tz_introduce_video{
      background: rgba(38, 40, 52, 0.9) none repeat scroll 0 0;
      width: 100%;
    }
    .tz-video{
      display: inline-block;
      float: none;
      position: absolute;
      right: 50%;
      top: 77%;
      z-index: 2;
    }
  }
  #jsn_form_2.jsn-uniform.jsn-master{
    padding: 50px 15px;
  }
  body{
    #padding129{
      padding-top: 0 !important;
    }
    #tz-questions-wrapper{
      padding: 0 !important;
    }
    #tz-conference-wrapper{
      padding: 77px 0 50px 0 !important;
    }
  }
  .conference-content .tz_meetup_btn{
    padding: 25px 0 0 0 !important;
  }
  div.tz-carousel-image .slider-height {
    position: absolute !important;
    height: 100% !important;
  }
  .tz_meetup_video_text{
    p:last-child{
      text-align: center;
    }
  }
  .fix_logo_top{
    position: absolute;
    top: 0;
  }
  .tz-conten-blog{
    padding: 15px 15px 0;
  }
  .blog .items-row .item.column-1{
    padding: 10px;
  }

  .header1{
    .row{
      padding: 9px 0;
    }
    .navbar-inner{
      position: relative;
    }
    .search_iconres{
      display: inline-block;
      opacity: 1;
      color: #ffffff;
      background: #e45914;
      padding: 4.2px 16px;
      position: absolute;
      right: 86px;
      top: 16px;
      border-radius: 3px;
    }
    .search_icon{
      display: none;
      opacity: 0;
      overflow: hidden;
    }
  }

  .tz-row-left {
    padding: 50px 30px !important;
    text-align: left;
  }
  li.dropdown:hover .dropdown-menu {
    position: relative;
    clear: both;
    float: none;
  }
  .meetup_position2 .tz_h3:after{
    right: auto;
    left: 0;
  }
  .wrapper-carousel .slides-pagination {
    width: 100%;
    text-align: center;
    z-index: 0;
  }

  .tz-carousel-image{
    height: 500px !important;
    .slider-height{
      height: 500px !important;
    }
  }
  .wrapper-carousel .tz_content_width {
    padding: 30px 20px !important;
  }
 .top_left{
   .custom{
     text-align:  left;
     margin-bottom: 10px;
   }
 }
  .tzSocial_bg_meetup_style2 ul{
    float: left;
    padding: 0;
    margin: 0;
  }
  .contactform:after{
    content: none;
  }
  .contactform .max-email-box{
    width: 100%;
  }
  .tzSocial_bg_meetup_style2 ul li{
  float: left;
  }

  .header1 .plazart-megamenu > .searchid{
    bottom: 26px;
    left: 25%;
  }
  .header1 .plazart-megamenu > ul > li.search_icon i{
    color: #ffffff;
  }
  .plazart-megamenu{
    li.dropdown.mega{
      position: relative;
    }
    li.dropdown.mega.open{
      .caret::before{
         color: @cl-orange;
         content: "\f107";
      }
    }
    .mega-dropdown-menu{
      padding: 0;
      .mega-col-nav{
        padding: 0;
      }
    }
    .nav-child .mega-nav li{
      border-bottom: 0;
    }
    .dropdown.mega .mega-dropdown-inner .mega-nav a{
      color: #777777;

    }
    //ul.navbar-nav > li{
    //    > a{
    //      background: #242424;
    //      color: @cl-orange;
    //    }
    //
    //}
    .mega .mega-dropdown-menu {
      background: #dad9d9 none repeat scroll 0 0;
      li{
        padding: 0;
        a{
          padding: 15px 20px !important;
          border-bottom: 0;

        }

      }
    }
    .nav .crt_active .caret{
      &:before{
        content: "\f107";
        color: @cl-orange;

      }
    }
    .plazart-megamenu .mega .mega-dropdown-menu ul li a{
      color: #777777;
    }
    .nav .caret{
      position: absolute;
      top: 15px;
      right: 22px;
      border: 0;
      z-index: 10;
      display: inline-block !important;
      color: #000000;
      height: auto;
      margin-left: 0;
      width: auto;
      &:before{
        content: "\f105";
        font-family: "fontawesome";
        color: #242424;
        .transition(300ms);
      }
    }
  }

  .navbar-default .plazart-megamenu ul > li > a{
    color: #242424;
    margin: 0;
    line-height: 3.4em;
    padding: 10px;
    border-bottom: 1px solid #e1dede;
  }
 .navbar-default .navbar-nav > li > a:hover, .navbar-default .navbar-nav > li > a:focus,.navbar-default .navbar-nav > li.open > a, .navbar-default .navbar-nav > li.open > a:hover,.navbar-default .navbar-nav > li.crt_active > a{
    color: @cl-orange !important;
    background: #242424 !important;
    border-right: none;
    border-bottom: 1px solid @cl-orange;
  }

  .header1 .navbar-inner{
    position: inherit;
  }
  .wrapper-slideshows .tz-slider-image .slides-navigation{
    z-index: 9;
  }

  .wrapper-slideshows .tz-slider-image .slides-pagination{
    z-index: 9;
  }
  .plazart-megamenu ul.navbar-nav{
    width: 100%;
  }
  .tz-homeType2 .plazart-megamenu > ul > li > a{
    padding: 13px 5px;
  }
  .navbar,.pull-rigtht{
    position: static;
  }
  .plazart-megamenu ul.navbar-nav > li{
    padding: 0;
    border-bottom: 1px solid #fff;
  }

  .plazart-megamenu ul.navbar-nav > li > a:before{
    height: 37px;
    opacity: 0.3;
    bottom: 5%;
  }
  .navbar-nav{
    margin: 0;
  }
  .plazart-megamenu ul.navbar-nav{
    float: none;
  }
  #plazart-mainnav .navbar-inner .nav-collapse.navbar-collapse {
    background: #eee;
    border-top: 2px solid #f2ce3e;
    position: absolute;
    width: 100%;
    left: 0;
    max-height: 480px;
    overflow-y: auto;
    padding: 0;
    z-index: 9999;
    margin-bottom: 0 !important;
    -webkit-box-shadow: 0 2px 3px -3px #cccccc;
    box-shadow: 0 2px 3px -3px #cccccc;
  }
  .plazart-megamenu ul.navbar-nav > li > a:before{
    height: 100%;
    opacity: 0.3;
    bottom: 5%;
    content: none;
  }

  .tz-homeType2 .plazart-megamenu > ul > li.current{
    > a{
      border-color: #e45914;
      color: #e45914;
      background-color: #262939 !important;
    }
  }

  .tz-homeType2{
    .plazart-megamenu > ul > li{
      &.open{
        a{
          border-left: 3px solid @cl-orange;
          border-right: none;
          border-bottom: 1px solid @cl-orange;
          background-color: #262939 !important;
          color: @cl-orange;
        }
      }
      > a{
        color: #454545;
        box-shadow: none;
        padding: 15px 20px;
        border-left: 3px solid #eee;
        border-right: none;
        border-bottom: 1px solid #e1dede;
        box-shadow: none;
        .transfrom(200ms);
      }
    }
  }
  #tz-logo a{
    padding: 18px 0 18px 15px;
  }

  .navbar-toggle{
    color: #fff;
    background: #e45914;
    padding: 4px 17px;
    margin-top: 14px;
    margin-bottom: 14px;
    &.btn:hover{
      color: #fff;
    }
  }
  .service-list{
    .service-item{
      padding-bottom: 40px;
    }
  }
  .circular_item{
    width: 50%;

  }
  .tz_discounts{
    .tz_button1{
      text-align: center;
      padding-top: 20px;
    }
  }
  .bg_img_resposiver{
    position: relative;
    display: block;
    &:after{
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 2;
      background: rgba(0, 0, 0, 0.6) none repeat scroll 0 0;
    }

  }
  #tz-introduce-video-wrapper{
    background-size: 100% 100%;
  }

  .wrapper-carousel .lwp_container{
    position: relative;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 2;
  }
  .easy-opener-content{
    top: 300px;
  }
  //.sv-image{
  //  .layout-z0{
  //    img{
  //      height: 100% !important;
  //    }
  //  }
  //}
  .TzInformation{
    .tz_media{
      width: 50%;
    }
  }
  .conference-content ul{
    padding-left: 0;
  }
  .tz_register_meetup_pricing{
    margin: 30px 0;
  }
  .TzContactInfo{
    padding: 40px 0;
  }
  .tz_register_meetup{
    width: 95%;
  }
  .wrapper-meetup-video .tz-video{
    top: 81%;
  }
  .wrapper-meetup-video .tz-video .tz_btn_easy{
    right: -15%;
  }
  body #padding102{
    padding-top: 0 !important;
  }
}
@media (max-width: 480px){
  .col-sb{
    width: 100%;
  }
  .hikashop_global_image_div{
    .lSSlideWrapper{
      height: 370px !important;
    }
  }
  .padding_bottom_suffix_2 {
    padding-bottom: 0;
  }
  .slick-slide{
    padding: 0;
  }
  #tz-pricing_sc .plg-center{
    padding-top: 0 !important;
  }
  #tz-sponsors{
    .plg-center{
      padding-top: 30px !important;
    }
  }
  #tz-sponsors-wrapper{
    padding: 50px 0!important;
  }
  .tab-content{
    margin-left: 0;
    margin-top: 0;
  }
  .tz_meetup_title_btn{
    font-size: 18px;
  }
  #tz-questions{
    .plg-left{
      padding-bottom: 6px !important;
      padding-top: 71px !important;
    }
  }
  .question-list .question-item{
    margin-bottom: 30px;
  }
  #gallery{
    padding-top: 40px !important;
  }
  #padding111.title_suffix{
    padding-bottom: 40px !important;
  }
  .wrapper-slideshows .tz-counter{
    margin-top: 15px;
  }
  .tz_event_meetup_content .tz_meetup_box_detail h4{
    font-size: 12px;
    margin-bottom: 4px;
  }
  .tz_event_meetup_content .tz_meetup_box_detail .tz_event_meetup_item_content p:first-child{
    margin-top: 0;
  }
  .tz_event_meetup_content .tz_meetup_box_detail .tz_event_meetup_item_content p{
    color: rgb(51, 51, 51);
    font-size: 11px;
    line-height: 16.5px;
  }


  .btn-toolbar{
    .btn.jsn-form-submit{
      float: none;
    }
  }
  .bg_img_resposiver{
    height: 300px !important;
  }
  .tz_discounts{
    margin: 44px -15px 50px -15px;
    h3{
      font-size: 16px;
    }
    .meetup_btn{
      padding: 10px 25px;
    }
    .vc_column-inner{
      margin: 0 -15px;
    }
  }
  .bg_img_resposiver img{
    height: 300px !important;
  }
  .wrapper-meetup-video .tz-video .tz_btn_easy{
    right: -30%;
  }
  .tz_event_meetup_content .tz_meetup_box_detail span.tz_meetup_start_time{
    font-size: 10px;
  }
  .wrapper-meetup-video .tz-video {
    top: 85%;
  }
  .contact-address .contact_form{
    clear: both;
    float: left;
    padding-top: 25px;
  }
  .tz_wpadd{
    width: 100%;
    float: left;
    margin-bottom: 0;
  }
  .grid-item{
    width: 100% !important;
  }
  .TzInformation .tz_media{
    width: 100%;
  }
  .tz_meetup_btn a{
    padding: 13px 21px;
  }
  .wrapper-slideshows .tz-slider-text h4{
    font-size: 23px;
  }
  .wrapper-slideshows .tz-counter input{
    font-size: 14px;
    height: 23px;
  }

  .wrapper-slideshows .tz-counter .tz-coun input{
    margin: 0;
  }
  .wrapper-slideshows .tz-counter span{
    margin-right: -5px;
  }
  #jsn_form_2.jsn-uniform.jsn-master{
    padding: 70px 15px;
  }
  .tz_event_meetup_content .tz_meetup_box_detail:before{
    left: -54px;
  }
  .tz_event_meetup_content .tz_meetup_box_detail span.tz_meetup_start_time{
    left: -43px;
    top: 22px;
  }
  .tz_event_meetup_content{
    padding-left: 45px;
  }
  .tz_event_meetup_content .tz_meetup_box_detail .tz_meetup_box_detail_custom{
    padding: 24px 0 25px 22px;
  }
  .wrapper-slideshows .tz-counter .tz-coun{
    margin: 0;
  }
  .wrapper-slideshows .tz-counter .tz-coun{
    width: 50px;
  }
  .wrapper-slideshows .tz-counter span{
    font-size: 10px;
  }

  .wrapper-slideshows .tz-counter .tz-coun:before{
    height: 16px;
  }
  .tz-carousel-image{
    height: 400px !important;
    .slider-height{
      height: 400px !important;
    }
  }
  h3, .h3 {
    font-size: 16px;
  }
  .tz_title_1:after{
    float: none;
    margin: 30px auto 0;
  }
  .tz-right-video{
    text-align: center;
  }
  .wrapper-meetup-video{
    h3{
      margin-bottom: 0;
      text-align: center;
    }
    .tz_introduce_title{
      text-align: center;
      width: 100%;
    }
    .tz_content p{
      text-align:  center;
    }
  }
  .wrapper-meetup-video{
    p{
      text-align: center;
    }
  }

  .meetup_position2 .tz_h3{
    font-size: 16px;
    text-align: left;
  }
  .meetup_position2 .tz_h3:after{
    right: 45%;
  }
  .meetup_position2 p{
    text-align: left;
  }
  .tz_register_meetup_pricing_item_price h3{
    font-size: 23px;
  }
  .tztwd-container .tweets_txt{
    font-size: 14px;
  }
}
@media (max-width: 370px){
  .wrapper-meetup-video .tz-video {
    top: 85%;
  }
  .wrapper-meetup-video .tz-video .tz_btn_easy {
    right: -20%;
  }
  .contactform .clear input.button{
    padding: 5px 10px;
  }
}
@media (max-width: 360px){

  .meetup_position2 .meetup_btn{
    text-align: center;
    width: 100%;
  }
  .circular_item{
    width: 100%;
  }

  .wrapper-carousel{
    .tz_content_width{
      p{
        text-align: center;
        .meetup_btn{
          margin-bottom: 15px;
        }
      }
    }
  }
  .wrapper-carousel .tz_content_width h3{
    text-align: center;
  }
  .wrapper-carousel .tz_content_width h3:after{
    right: 45%;
  }

  .contactform .max-email-box{
    width: 100%;
  }
  .contactform:after{
    content: none;
  }
  .tz_register_meetup_pricing{
    margin-right: 0;
  }
  .tz_register_meetup{
    width: 100%;
  }
}
@media (max-width: 318px){
  .wrapper-slideshows .tz_slider_meetup_btn li {
    margin: 10px 8px;
  }
  .wrapper-slideshows .tz-counter .tz-coun:before{
    top: 45%;
  }
}