html[dir="rtl"] {
    overflow-x: hidden;
  #tz-video{
    direction: ltr;
    .tz-right-video{
      direction: rtl;
    }
  }
  #tz-slider,#tz-carosel-wrapper,#tz-information-wrapper,#sponsors,#tz-recent_post{
    direction: ltr;
  }
  .tz_event_meetup_content{
    border-left: 0;
    border-right: 3px solid #eee;
    padding-right: 125px;
    padding-left: 0;
    .tz_meetup_box_detail:before{
      left: auto;
      right: -136px;
    }
    .tz_meetup_box_detail span.tz_meetup_start_time{
      left: auto;
      right: -110px;
    }
  }
  #jsn_form_2.jsn-uniform.jsn-master{
    padding: 98px 95px 95px 158px;
  }
  .tz_register_meetup{
    margin: 0 auto 0 0;
  }
  #tz-header-wrapper a {
    text-align: right;
  }
  .relative_footer .absolute_footer i{
    padding-right: 10px;
  }
  .slick-slider{
    .slick-next:before{
      content: "\f105" !important;
    }
    .slick-prev:before{
      content: "\f104";
    }
  }
  ul.tz_recent_posts .slick-next{
    right: -125px;
    left: auto;
  }
  .conference-content{
    text-align:  right;
  }
  .tz-row-left {
    padding: 75px 12% 58px 73px !important;
    text-align: right;
  }
  .tz-carousel-image .pull-right{
    direction: rtl;
  }
  .slick-slide .mod_recent_content{
    text-align: right;
  }
  .tzSocial_bg_meetup_style2 ul li{
    float: right;
  }
  .style_top .tz_meetup_header_option_phone span:first-child{
    margin-right: 0;
    margin-left:  40px;
  }
  .plazart-megamenu ul.navbar-nav{
    float: left;
    padding: 0;
  }

  #tz-logo a{
    padding: 36px 0 35px 40px;
  }
  #tz-menu-wrapper a{
    text-align: right;
  }
  .style_top .tz_meetup_header_option_phone span img{
    margin-left: 5px;
    margin-right: -5px;
  }
  .search_icon {
    margin: 35px 15px 0 0 ;
  }
  .tzSocial_bg_meetup_style2 ul li:first-child a {
    padding-right: 14px;
    padding-left: 2px;
  }
  .plg-left,.tz_meetup_header_option_phone{
    text-align: right;
  }
  h3.module-title::before{
    left: auto;
    right: 0;
  }
  .tztwd_blog_container{
    .tweets_txt{
      padding-left: 0 !important;
      padding-right: 43px !important;
    }
    ul li:after{
      left: auto;
      right: 0;
    }
  }
  #manufacturers{
    direction: ltr;
  }
  .TzInformation .media-body ul.tz-plazart-list li p, .our_speakers .media-body ul.tz-plazart-list li p{
    padding-left: 0;
    padding-right: 30px;
  }
  .tz-plazart-list li span{
    float:  right;
  }
  .item-page dt.published{
    border-right: 0;
    padding-right:  0;
  }
  .item-page .Info_article .article-info dd{
    .category-name{
      border-right: 1px solid #95979d;
    }
  }
  #comments .comments-list{
    margin-right: 0;
    margin-left: 20px;
  }
  #comments-form p, #comments-form #comments-form-buttons, #comments-form #comments-form-error, #comments-form #comments-form-captcha-holder{
    margin-right: 0 !important;
  }
  #jc{
    .tz-comment .fa{
      right: auto;
      left: 25px;
    }
    #comments-form{
      margin: 0 -15px !important;
    }
  }
  .contact_form p i.fa{
    right: auto;
    left: 10px
  }
  #mirror-grid{
    direction: ltr;
  }
  .hikashop_filter_module .hikashop_filter_button_outside{
    right: auto;
    left: 0;
  }
 

  .hikashop_products{
    direction: ltr;
  }
  .item-shoptz .tz_top{
     direction: ltr;
  }
  .hikashop_product_name{
    a{
      text-align:  right;
    }
  }
  .hikashop_cart_module .hikashop_cart{
    right: -100px;
  }
  .tz_register_meetup_pricing_item_container{
    text-align: right;
  }

  .tz-plazart-list li p{
    margin-left: 0;
    margin-right: 30px;
  }
  .tzSocial_bg_meetup_style2 ul{
    float: left;
  }
  .tz-form-close{
    left: 30px;
    right: auto;
  }
  .pull-left {
    float: right !important;
  }
  .col-sb{
    text-align: right;
  }
  .top_left{
    float: right;

  }

  .tz_wpadd .cont-tt {
    padding-right: 65px;
    padding-left: 0;
    text-align: right;
  }
  .tz_wpadd .all-icadd span,.hk-rating a,.hikashop_product_quantity_main{
     float: right;
  }
  .tz_wpadd .all-icadd h4{
    margin-left: 0;
    margin-right: 65px;
    text-align: right;
  }
  .contact_form input,.contact_form textarea,input,textarea{
    text-align: right;
  }
  .hikashop_product_right_part{
    text-align: right;
  }
  span.hikashop_product_price_full{
    text-align: right !important;
  }
  .hikashop_product_vote_mini{
    float: right;
    width: 100%;
    display:  block;
  }

  .lnk_berum{
    float:  left;
  }
  .typography_count:before{
    left: auto;
    right: 0;
  }
  @media (max-width: 767px){
    .navbar-toggle {
      float:  left;
      margin-top: 24px;
    }
    .navbar-nav > li{
      float: right;
    }
  }
  .wrapper-carousel .lwp_container{
    right: auto;
    left: 0;
  }
  .tz_maniva_meetup_title {
    margin-right:0;
    margin-left: 211px;
  }
  .wrapper-carousel .tz_content_width{
    padding: 29px 80px 81px 0 !important;
  }
  .wrapper-carousel .slides-pagination{
    width: 60%;
  }
  .vc_custom_jl{
    float:  right;
  }
  .tz_register_meetup_pricing_item_price{
    right: auto;
    left: 0;
  }
  .tz_register_meetup_pricing_item_container,.btn-toolbar .btn, .btn-toolbar .btn-group, .btn-toolbar .input-group{
    float: right;
  }
  .tz_discounts > div > div{
    float: right;
  }
  .slick-slide .meetup_date{
    left: auto;
    right: 0;
  }
  .tz_register_meetup_pricing_item_container,.tz_register_meetup_pricing_item_price{
    border-radius: 0;
  }
  .relative_footer .absolute_footer{
    left: 13em;
    right: auto;
  }
  .contactform .clear input.button{
    left: 20px;
    right: auto;
  }
  .tz_register_meetup_pricing_item.active .tz_register_meetup_pricing_item_price::after{
    left: -15px;
    right: auto;
    border-left: 0;
    border-right: 25px solid #e45914;
  }
  .media-body{
    text-align: right;
  }
}