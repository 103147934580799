.full-width{
  padding: 0;
  margin: 0;
}
//
.table-cell{
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}
//
.ds-table{
  display: table;
  height: 100%;
  width: 100%;
  .ds-table-cell{
    display: table-cell;
    vertical-align: middle;
    text-align: center;
  }
}
//
.img-fixer{
  background-size: 100%;
}
//
.tz-center{
  text-align: center;
}
//
.tz_h3{
  margin-bottom: 11px;
  margin-top: 0;
  text-transform: uppercase;
  font-size: 26px;
}
//
.count_title{
  background: #000 none repeat scroll 0 0;
  display: block;
  float: none;
  height: 1px;
  margin: 28px auto 41px;
  width: 80px;
  position: relative;
  &:before{
    background: @cl-orange none repeat scroll 0 0;
    content: "";
    height: 6px;
    position: absolute;
    right: 44%;
    top: -3px;
    width: 10px;
    z-index: 2;
  }
  &:after{
    background: @cl-white none repeat scroll 0 0;
    content: "";
    height: 7px;
    position: absolute;
    right: 37%;
    top: -2px;
    width: 22px;
    z-index: 1;
  }
}
//
.count_title2{
  &:after{
    content: "";
    background: @cl_black;
    display: block;
    float: right;
    height: 1px;
    margin-top: 30px;
    position: absolute;
    width: 30px;
    z-index: 10;
  }
}
//
.meetup_btn{
  text-decoration: none;
  color: #ffffff;
  font-weight: 500;
  background: #e45914;
  padding: 10px 35px;
  display: inline-block;
  font-size: 12px;
  border: 2px solid #e45914;
  margin: 0 1px;
  .border-radius(25px);
  letter-spacing: 3px;
  text-transform: uppercase;
  .transition(0.2s);
  cursor: pointer;
  vertical-align: middle;
  word-wrap: break-word;

}
//
.tz_btn_1{
  background: @cl-orange;
  color: @cl-white;
  &:hover,&:focus{
    color: @cl-white;
    background: transparent;
    border-color: @cl-orange;
  }
}
//
.tz_btn_2{
  background: transparent;
  border-color: @cl-white !important;
  color: @cl-white !important;
  &:hover,&:focus{
    color: #000 !important;
    background: @cl-white !important;
  }
}
.tz_btn_3{
  background: transparent;
  border-color: #000 !important;
  color: #000 !important;
  &:hover,&:focus{
    color: @cl-white !important;
    background: #000 !important;
  }
}
//
.tz-meetup-padding{
  padding: 85px 0;
}
//
.tztwd-container{
  .tzTwitter-icon{
    background-image: url("../../../images/border-tw.png");
    background-position: center center;
    background-repeat: no-repeat;
    padding: 28px 0 32px;
    text-align: center;
  }
}
.tz_register_meetup_pricing_item_price {
  background-image: url("../../../images/price-pay.png");
  background-color: #e45914;
  background-position: center center;
  background-repeat: no-repeat;
}
#tz-flickr ul.flickr li a:before{
  font-family: FontAwesome;
}
.tztwd_blog_container ul li:after{
  font-family: FontAwesome;
}
.meetup_btn.small_btn{
  font-size: 12px;
  padding: 11px 16px;
}
.meetup_btn.medium_btn{
  padding: 14px 20px 14px 48px;
  font-size: 14px;
}
.meetup_btn.lg_btn{
  font-size: 16px;
  padding: 18px 25px;
}
.meetup_btn.pink_btn{
  background-color: #fe6c61;
  border-color: #fe6c61;
  color: #ffffff;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.01) 100%);
  background-repeat: repeat-x;
}
.meetup_btn.brown_btn{
  background-color: #555555;
  color: #ffffff;
  border-color: #555555;
}
.meetup_btn.primary_btn{
  background-color: #0088cc;
  border-color: #0088cc;
  color: #ffffff;
}
.meetup_btn.success_btn{
  background-color: #6ab165;
  border-color: #6ab165;
  color: #ffffff;
}
.meetup_btn.warning_btn{
  background-color: rgba(0, 0, 0, 0);
  border-color: #ff9900;
  color: #ff9900;
}
.tz_opacity_2{
  position: relative;
  &:before{
    background-color: rgba(0, 0, 0, 0.8);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}