@import "mixins";
@import "value";
@import "short_code";
@import "animation";
//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++


body{
  margin: 0;
  padding: 0;
  font-weight: 300;
  line-height: 1.85em;
  font-size: 14px;
  color: #333;
}
:focus {
  outline: none !important;
}
html.overflow_y{
  overflow-y: hidden;
}
p{
  font-weight: 300;
}
.tz-row-left{
  padding: 75px 73px 58px 12% !important;
  text-align: right;
}
img{
  width: auto;
}
a {
  color: #e45914;
}
h4, .h4, h5, .h5, h6, .h6,h1, .h1, h2, .h2, h3, .h3{
  margin-top: 0;
}
h1, h2, h3, h4, h5, h6{
  color: #242732;
  font-weight: 700;
  line-height: 1.4em;
  margin-top: 0;
}
.btn{
  line-height: inherit;
}
input {
  border: 1px solid #dddddd;
  box-shadow: none;
  line-height: normal;
  padding: 5px 7px;
  .transition(200ms);
  &:hover{
    border-color: @cl-orange;
  }
}
.vc_custom{
  background-color: rgba(36, 39, 50, 0.9) !important;
}
a{
  .transition(300ms);

  &:focus{
    outline: medium none;
  }
}

li,a{
  list-style-type: none;
  text-decoration: none;
  &:hover,&:focus{
    list-style-type: none;
    text-decoration: none;
  }
}


//fix-------------------------------------------------------------------------------------------------------------------
body{
  #tz-main-body-wrapper{
    padding: 0 !important;
  }
}

.plg-center{
  text-align: center;
}

.plg-left{
  text-align: left;
}

.plg-right{
  text-align: right;
}

//--sufixx
.title_suffix{
  .count_title{
    background: @cl-white none repeat scroll 0 0;
    &::after{
      background-color: #242732;
    }
  }
}

.padding_suffix{
    padding-top: 90px;
    padding-bottom: 100px;
}
.suffix_count{
  background: @cl-white none repeat scroll 0 0;
  &:after{
    background: #272c3c none repeat scroll 0 0;
  }
}
.padding_top_suffix{
  padding-top: 75px;
}
.padding_bottom_suffix{
  padding-bottom: 80px;
}
.padding_bottom_suffix_2{
  padding-bottom: 60px;
}
.suffix_btn_meetup_center{
  text-align: center;
}
.tzRow_ArrowType4{
  border-left: 60px solid rgba(0, 0, 0, 0);
  border-right: 60px solid rgba(0, 0, 0, 0);
  border-top: 30px solid #e45914;
  left: 50%;
  margin-left: -62px;
  position: absolute;
  top: 100%;
  z-index: 1;
}

//top+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
.navbar {
  margin-bottom: 0;
}
.question-list{
  .col-md-4{
    &:nth-child(+3n+1){
      clear: both;
    }
}




}
.plazart-megamenu{
  div.mega-inner{
    padding: 0;
  }
  .nav-child{
    .mega-nav{
      li{
        list-style: outside none none;
        padding: 0 10px 3px;
        position: relative;
        line-height: 25.9px;
        border-bottom: 1px solid rgba(73, 77, 84, 0.1);
        &:last-child{
          border-bottom: 0;
        }
        a{
          color: rgb(255, 255, 255);
          display: block;
          font-size: 11px;
          font-weight: 400;
          line-height: 13px;
          padding: 13px 0 !important;
          text-transform: uppercase;
        }
      }
    }
  }
  .mega .mega-dropdown-menu{
    background: rgba(0,0,0,0.7);
    border-top: 1px solid @cl-orange;
    ul{
      li a{
        margin: 0;
        color: #ffffff;
        &:before{
          content: none;
        }
        &:hover{
          color: #ffffff;
        }
      }
    }
  }
  ul.navbar-nav{
    float: right;
    li.search_icon{
        padding: 0;
    }

    >li.current{
      >a{
        &:before {
          width: 100%;
        }
      }
    }
    >li.active{
      >a{
        &:before {
          width: 100%;
        }
      }
    }
    >li{
      position: relative;
      padding: 0 14px;
      >a{
        margin: 40px 0;
        font-size: 12px;
        font-weight: 600;
        color: #242732;
        line-height: 1.4em;
        display: block;
        padding: 0 14px;
        text-transform: uppercase;
        .transition(0.2s);
        &:hover{
          &:before{
            width: 100%;
          }
        }
        &:before{
          position: absolute;
          bottom: 35%;
          left: 0;
          content: '';
          background: #e45914;
          height: 3px;
          width: 0;
          .transition(0.3s);
        }
      }
    }
  }
}
.nav>li>a:focus, .nav>li>a:hover {
  background-color: transparent !important;
}
.nav>li.open a{
  background-color: transparent !important;
}
.tz-homeType2{
  border-bottom: 1px solid rgba(255,255,255,.1);
  width: auto;
  background: none repeat scroll 0 0 rgba(0,0,0,0);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 21;
  .transition(0.3s);
  .plazart-megamenu{
    >ul{
      >li{
        >a{
          margin: 0;
          padding: 40px 0;
          color: @cl-white;
        }
      }
    }
  }
}
#tz-logo{
  a{
    padding: 36px 40px 35px 0;
    text-decoration: none;
    .transition(0.3s);
    display: block;
    img{
      max-width: 100%;
      height: auto;
      width: auto;
    }
  }
}
//slider++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

.wrapper-slideshows{
  .tz_content_slider_meetup{
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 8;
  }
  .tz_slider_meetup_btn{
    ul{
      padding: 0;
    }
    li{
      display: inline-block;
    }
  }
  .tz-slider-text{
    color: @cl-white;
    p{
      font-weight: 400;
      letter-spacing: 1px;
      margin-bottom: 0;
    }
    h4{
      color: #ffffff;
      font-size: 60px;
      font-weight: 200;
      line-height: 1em;
      margin-bottom: 0;
      strong{
        font-weight: 700;
      }
    }
  }
  .tz_social{
    display: inline-block;
    margin-bottom: 9px;
    position: relative;
    &:after{
      content: "";
      background: @cl-white;
      height: 1px;
      right: 100%;
      position: absolute;
      top: 50%;
      width: 25px;
    }
    &:before{
      content: "";
      background: @cl-white;
      height: 1px;
      left: 100%;
      position: absolute;
      top: 50%;
      width: 25px;
    }
    a{
      color: @cl-white;
      font-size: 18px;
      margin: 0 10px;
    }
  }
  .tz-counter{
    margin-bottom: 19px;
    input{
      line-height: 0.5;
      background: transparent none repeat scroll 0 0;
      border: 0 none;
      color: #ffffff;
      font-size: 63px;
      height: 79px;
      margin-left: 0;
      margin-top: 0;
      padding: 0;
      text-align: center;
      width: 100%;
      font-weight: 100;
    }
    span{
      color: @cl-white;
      font-size: 16px;
      font-weight: 600;
      margin-right: -30px;
      text-transform: uppercase;
    }
    .tz-coun{
      display: inline-block;
      width: 100px;
      text-align: center;
      margin: 0 15px;
      input{
        margin: 0 15px;
      }
      &:first-child{
        &:before {
          width: 0;
        }
      }
      &:before{
        content: "";
        background: #e45914;
        height: 25px;
        width: 1px;
        position: absolute;
        top: 51%;

      }
    }
  }
  .tz-slider-image{
    position: relative;
    .slides-navigation{
      z-index: 10;
      a{
        &.prev{
          left: 10px;
        }
        &.next{
          right: 10px;
        }
      }
      .cracked{

        i{
          color: @cl-gray;
          &:before{
            font-size: 33px;
          }
        }
      }
    }
    .slides-pagination{
      z-index: 9;
      bottom: 40px;
      a{
        text-indent: -9999px;
        border: 2px solid @cl-orange;
        width: 15px;
        height: 15px;
        margin: 0 5px;
        outline: 0;
        .border-radius(50%);
      }
      a.current{
        background: @cl-orange;
      }
    }
  }
}
.layout-z1{
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.7);
  z-index: 50;
}
//WHAT ABOUT EVENT -----------------------------------------------------------------------------------------------------

.tz-title{
  h3{
    font-size: 26px;
    margin: 0 0 12px;
    text-transform: uppercase;
  }
  h3.tz_title_meetup_medium{
    font-weight: 500;
  }
}
.service-list{
  .tz_description{
    margin-bottom: 85px;
    p{
      font-size: 16px;
      margin: 0;
    }
  }
  .service-item{
    img{
      border-radius: 5px;
      width: 100%;
      max-width: 100%;
    }

    h4{
      font-size: 15px;
      font-weight: 600;
      letter-spacing: 2px;
      margin: 25px 0 18px;
      a{
        color: #242732;
        text-decoration: none;
        &:hover{
          color: @cl-orange;
        }
      }
    }
  }
}
//video-----------------------------------------------------------------------------------------------------------------

.no-pm{
  padding: 0;
}
.tz_sub {
  border-bottom: 1px solid @cl_gray_dark;
  display: block;
  padding-bottom: 5px;
  margin: 5px 0;
}
.tz_title_1{
  &:after{
    background: #e45914 none repeat scroll 0 0;
    content: " ";
    display: block;
    float: right;
    height: 1px;
    position: relative;
    width: 30px;
    z-index: 10;
    margin-top: 30px;
  }
}
.wrapper-meetup-video{
  background: rgba(0, 0, 0, 0.6) none repeat scroll 0 0;
  .tz_introduce_video{
    background: rgba(38, 40, 52, 0.9) none repeat scroll 0 0;
    width: 50%;
  }
  .meetup_btn{
    margin-top: 50px;
  }
  .tz-right-video{
    width: 100%;
  }
  .tz-row{
    padding: 75px 55px 81px 26% !important;
    text-align: right;
  }
  h3{
    color: #fff;
    margin-bottom: 41px;
    padding-bottom: 30px;
    margin-top: 0;
    text-transform: uppercase;
    font-weight: 700;

    em{
      font-style: normal;
      font-weight: 400;
      display: block;
      padding-top: 3px;
    }
  }
  .tz_introduce_title{
    color: @cl-orange;
    display: inline-block;
    font-weight: 500;

    text-transform: uppercase;

      i{
        margin-left: 5px;
        color: @cl-white;
      }

  }
  .tz_content{
    p{
      color: #ddd;
      margin-bottom: 25px;
    }
  }
  .tz-video{
    display: inline-block;
    float: none;
    position: absolute;
    right: 25%;
    top: 50%;
    z-index: 2;
    .bg-video{
      height: 650px;
      width: 100%;
      z-index: 1;
    }
    .tz_btn_easy{
      border: 1px solid @cl-white;
      border-radius: 50%;
      color: @cl-orange;
      display: inline-block;
      font-size: 30px;
      margin-top: -30px;
      margin-right: -30px;
      padding: 30px;
      position: relative;
      right: 25%;
      top: 50%;
      z-index: 2;
      background: rgba(38, 40, 52, 0.4) none repeat scroll 0 0;
      transition:(300ms);
      .transfrom(scale(1,1));

    }
  }
}
///statics\\\-----------------------------------------------------------------------------------------------------------
.tz_btn2_fix{
  border-color: @cl_black;
  color: @cl_black;
  .transition(200ms);
  &:hover{
    color: @cl-white;
    background: @cl_black;
  }
}
.circular_skills{
  .row{
    p{
      color: @cl-orange;
      font-weight: 800;
    }
    .icon{
      border: medium none;
      border-radius: 100%;
      height: 50px;
      margin: 0 auto;
      position: relative;
      .transition(300ms);
      width: 100px;
      .icon-content > div i{
        color: @cl-orange;
        background: transparent;
        font-size: 36px;
      }
    }
    .tz-counter-number{
      position: relative;
    }
    h3{
      color: @cl_black;
      font-size: 64px;
      font-style: normal;
      font-weight: 100;
      margin: 24px 0 30px;
      padding: 23px 0 28px;
      &:after{
        background: #c6c6c6 none repeat scroll 0 0;
        bottom: 0;
        content: "";
        height: 4px;
        left: 50%;
        margin-left: -12px;
        position: absolute;
        width: 24px;
      }
    }
  }
}
//discount||------------------------------------------------------------------------------------------------------------
.tz_discounts{
  margin: 20px 0 100px;
  h3{
    font-size: 28px;
    margin-bottom: 0;
  }
  p{
    color: rgba(36, 39, 50, 0.8);
    font-size: 16px;
  }
}
//work progress---------------------------------------------------------------------------------------------------------
.layout-z0{
  z-index: 0;
}
.tz_meetup_video_sub_title {
  color: @cl-orange;
  display: inline-block;
  font-weight: 500;
  margin-top: 5px;
  text-transform: uppercase;
}
.tz-plazart-list{
  margin: 25px 0 0;
  padding: 0;
  li{
    color: #242732;
    font-size: 16px;
    list-style: outside none none;
    padding: 12px 0 8px;
    &:last-child{
      margin-bottom: 35px;
    }
    &:before{
      clear: both;
      content: "";
      display: table;
    }
    span{
      display: inline-block;
      float: left;
      line-height: 0;
      margin-top: 7px;
      i{
        color: #e54053;
      }
    }
    p{
      color: #242732;
      margin-bottom: 0;
      margin-left: 30px;
      position: relative;
      &:after{
        border-bottom: 1px dashed #dbd9d9;
        bottom: -12px;
        content: "";
        position: absolute;
        right: 0;
        width: 100%;
      }
    }

  }
}
.wrapper-carousel{
  i{
    font-size: 18px;
  }
  .lwp_container{
    position: absolute;
    right: 0;
    top: 0;
    width: 50%;
    z-index: 2;
  }
  .tz_content_width{
    padding: 29px 0 81px 80px !important;
    float: right;
    background: rgba(255, 255, 255, 0.9);
    h3{
      color: @cl_black;
      margin: 24px 0 43px;
      padding: 23px 0 25px;
      .count_title2;
      &:after{
        margin-top: 25px;
      }
    }
  }
  .tz_meetup_video_sub_title{
    .tz_sub;
    display: inline-block;
    border-bottom-color: #ddd;
    i{
      color: @cl_black;

    }
  }
  .layout-z0{
    img{
      top: 0 !important;
    }
  }
  .tz_icon_maniva_list{
    display: inline-block;
    float: left;
    line-height: 0;
    margin-top: 7px;
  }
  .slides-pagination{
    z-index: 10;
    bottom: 40px;
    text-align: right;
    width: 48%;
    a{
      text-indent: -9999px;
      border: 2px solid @cl-orange;
      width: 15px;
      height: 15px;
      margin: 0 5px;
      outline: 0;
      color: transparent;
      .border-radius(50%);
    }
    a.current{
      background: @cl-orange;
    }
  }
}
.tz_maniva_meetup_title{
  margin-right: 211px;
  .tz_meetup_content{
   padding-bottom: 47px;
  }
}
// meetup position1-----------------------------------------------------------------------------------------------------
.tz_meetup_positionv1{
  background: @cl_black;
  overflow: hidden;
  text-align: center;
  .tz-meetup-padding;
  h3{

    color: #f8f8f8;
    display: inline-block;
    font-size: 24px;
    letter-spacing: 3px;
    margin: 0;
    padding: 0 45px;
    position: relative;
    text-align: center;
    em{
      font-style: normal;
      font-weight: 400;
    }
    &:before{
      content: "";
      background: #e45914 none repeat scroll 0 0;
      height: 1px;
      left: 100%;
      position: absolute;
      top: 50%;
      width: 100%;
    }
    &:after{
      content: "";
      background: #e45914 none repeat scroll 0 0;
      height: 1px;
      position: absolute;
      right: 100%;
      top: 50%;
      width: 100%;
    }
  }
}
// meetup position2-----------------------------------------------------------------------------------------------------
.meetup_position2 {
  color: #fff;
  margin-bottom: 11px;
  margin-top: 0;

  .tz_h3 {
    display: block;
    width: 100%;
    position: relative;
    margin: 0 0 38px;
    padding: 0 0 31px;
    text-transform: uppercase;
    color: #fff;
    .count_title2;
    &:after{
      background: @cl-white;
      right: 0;
    }
  }
  p{
    color: #ddd;
    margin-bottom: 25px;
  }
}
//information-----------------------------------------------------------------------------------------------------------
.tz-information, .tz_media{
  padding: 0;
}
.tz_information_thumbnail{

}
.ds-table-cell{
  .ds-table;
}
.tz_thumbnail{
  cursor: pointer;
  overflow: hidden;
  position: relative;
  .transition(400ms);
  img{
    .transition(400ms);
    position: relative;
  }
  &:hover{
    &::after{
      background: rgba(0, 0, 0, 0.7) none repeat scroll 0 0;
    }
    img{
      .transfrom(scale(1.2));
      //.transition(400ms);
    }
    h4.tz_meetup_employment{
      opacity: 1;
      transform: translateY(0px);
      transition: all 0.4s ease 0.2s;
    }
    h3.tz_meetup_name{
      opacity: 1;
      transform: translateY(0px);
      transition: all 0.4s ease 0.35s;
    }
    .tz_social{
      opacity: 1;
      transform: translateY(0px);
      transition: all 0.4s ease 0.5s;
    }
  }
  &::after{
    background: rgba(0, 0, 0, 0.2) none repeat scroll 0 0;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: all 0.4s linear 0.1s;
    width: 100%;
  }
  .tz_member_meetup{
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;

  }
  h4.tz_meetup_employment{
    color: #fff;
    font-size: 11px;
    font-weight: 600;
    letter-spacing: 1px;
    opacity: 0;
    text-transform: uppercase;
    .transfrom(translateY(30px));
    .transition(100ms);
    &:hover{
      color: @cl-orange;
    }
  }
  h3.tz_meetup_name{
    color: #fff;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 1px;
    opacity: 0;
    text-transform: uppercase;
    .transfrom(translateY(30px));
    .transition(100ms);
    &:hover{
      color: @cl-orange;
    }
  }
  .tz_social{
    margin-top: 10px;
    opacity: 0;
    .transfrom(translateY(30px));
    .transition(400ms);
    a{
      i{
        color: #fff;
        font-size: 16px;
        padding: 0 10px;
        .transition(100ms);

      }
    }
  }

}
.TzInformation,.our_speakers{
  .tz_modal_image_content{
    border: 5px solid rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    display: block;
    height: 250px;
    overflow: hidden;
    width: 250px;
  }
  .modal-dialog{
    margin: 125px auto;
    .tz_content{
      padding: 10px 25px;
    }

  }
  .tz_modal_image{
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    display: inline-block;
    height: 250px;
    left: 50%;
    margin-left: -125px;
    overflow: hidden;
    position: absolute;
    top: -125px;
    width: 250px;
    img{
      height: 250px;
      left: 50%;
      position: relative;
      transform: translateX(-50%);
      width: auto;
    }
  }
  .modal-header{
    border-bottom: 0;
    padding-bottom: 90px;
  }
  .tz_modal_title{
    text-align: center;
    h4.tz_modal_name{
      color: #e45914;
      font-family: Roboto,sans-serif;
      font-size: 30px;
      line-height: 1em;
      margin-bottom: 5px;
      text-transform: uppercase;
    }
    span{
      color: #898b90;
      display: block;
      margin-bottom: 4px;
    }
    .information_icon{
      a{
        color: #898b90;
        margin: 0 5px;
        transition: all 0.2s linear 0s;
        i{

        }
      }
    }
  }
  .media-body{
    p{
      line-height: 1.8em;
      padding-bottom: 25px;
    }
    .tz_list_item_content{
      padding-bottom: 0;
    }
    .modal-header{
      padding-bottom: 15px;
      float: none;
      text-align: center;
      .close{
        float: none;
        font-size: 50px;
      }
    }
    ul.tz-plazart-list{
      padding: 0;
      li p{
        font-size: 14px;
        line-height: 1.5em
      color: #242732;
        margin-bottom: 0;
        margin-left: 30px;
        position: relative;

        .tz_list_item_content::after{
          border-bottom: 1px dashed #dbd9d9;
          bottom: -12px;
          content: "";
          position: absolute;
          right: 0;
          width: 100%;
        }
      }
    }
  }
  .tz_modal_title{
    margin-bottom: 20px;
  }
}
.modal-lg{
  width: 1200px;
}
.ezcol.ezcol-one-half{
  width: 50%;
  float: left;
}
//conference------------------------------------------------------------------------------------------------------------
.conference-content{
  h3.title{
    margin-bottom: 0;
  }
  h1, h2, h3, h4, h5, h6{
    line-height: 1.85;
  }
  .tz_tabs_meetup{
    position: relative;
    &:after{
      background: rgba(0, 0, 0, 0) radial-gradient(circle farthest-side at right center , #fff 60%, #242732 100%) repeat scroll 0 0;
      content: "";
      height: 1px;
      position: absolute;
      right: 0;
      top: 50%;
      width: 16%;
      z-index: 1;
    }
    &:before{
      background: rgba(0, 0, 0, 0) radial-gradient(circle farthest-side at left center , #fff 60%, #242732 100%) repeat scroll 0 0;
      content: "";
      height: 1px;
      left: 0;
      position: absolute;
      top: 50%;
      width: 16%;
      z-index: 1;
    }
  }
  ul{
    text-align: center;
    position: relative;
    li.active a{
      background: #e45914 none repeat scroll 0 0;
      border-color: #e45914;
      color: #fff;
    }
    li{

      display: inline-block;
      float: none;
      margin: 0 5px 0 0;
      position: relative;
      z-index: 2;
      a{
        cursor: pointer;
        border: 1px solid #242732;
        border-radius: 25px;
        color: #242732;
        font-size: 11px;
        font-weight: 500;
        padding: 6px 30px;
        position: relative;
        text-shadow: none;
        text-transform: uppercase;
        display: block;
        background: #fff;
        line-height: 25.9px
      }
    }
    &:after{
      background: #242732 none repeat scroll 0 0;
      content: "";
      height: 1px;
      left: 0;
      position: absolute;
      top: 50%;
      width: 100%;
      display: table;
    }
    &:before{
      content: "";
      display: table;
    }
  }
}
.tab-content{
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  margin-right: 2px;
  margin-top: 70px;
  padding: 0;
  .tille_meetup_box{
    h3{
      a{
        color: #e45914;
        font-size: 20px;
        letter-spacing: 1px;
        margin: 0 0 5px;
        text-transform: uppercase;
      }
    }
  }

  h3{
    .tz_event_meetup_subtitle{
      color: #666;
      font-size: 14px;
      line-height: 1.1em;
      em{
        font-style: inherit;
        font-weight: 400;
      }
    }
  }
}
.tz_event_meettup_box_content{
  position: relative;
  &:before{
    background: rgba(0, 0, 0, 0) linear-gradient(to bottom, #fff 0px, rgba(255, 255, 255, 0.65) 35%, rgba(255, 255, 255, 0) 100%) repeat scroll 0 0;
    content: "";
    height: 50px;
    left: 0;
    position: absolute;
    top: 0;
    width: 3px;
    z-index: 1;
  }
  &:after{
    background: rgba(0, 0, 0, 0) linear-gradient(to top, #fff 30%, rgba(238, 238, 238, 0) 100%) repeat scroll 0 0;
    bottom: 0;
    content: "";
    height: 130px;
    left: 0;
    position: absolute;
    width: 3px;
    z-index: 1;
  }
}

.tz_event_meetup_content{
  border-left: 3px solid #eee;
  margin-top: 40px;
  padding-left: 125px;
  position: relative;
  .tz_meetup_box_detail{
    display: block;
    margin-bottom: 20px;
    position: relative;
    h4{
      color: #595c6a;
      font-size: 15px;
      text-transform: uppercase;
      font-weight: 700;
    }
    .tz_event_meetup_item_content{
      h5{
        color: #0b0c0f;
        font-weight: 200;
      }
      p{
        font-size: 13px;
        margin: 0;
        &:first-child{
          margin-top: 10px;
        }
        em{
          color: #888a8e;
          display: inline-block;
          font-size: 12px;
          font-weight: 500;
          margin-bottom: 10px;
        }
      }

    }
    &:before{
      background: #e45914 none repeat scroll 0 0;
      border: 5px solid #fff;
      border-radius: 50%;
      content: "";
      height: 20px;
      left: -136px;
      position: absolute;
      top: 45px;
      width: 20px;
      z-index: 2;
    }
    &:after{
      border-bottom: 20px solid #f8f8f8;
      border-left: 20px solid transparent;
      border-top: 20px solid transparent;
      content: "";
      left: -20px;
      position: absolute;
      top: 15px;
    }
    span.tz_meetup_start_time{
      color: #242732;
      font-size: 14px;
      font-weight: 300;
      left: -110px;
      position: absolute;
      top: 40px;
    }
    p{
      font-size: 11px;
      color: #333;
      i.fa{
        color: #e45914;
      }
    }
    .tz_meetup_box_detail_custom{
      background: #f8f8f8 none repeat scroll 0 0;
      border-radius: 10px;
      display: inline-block;
      padding: 24px 40px 25px 22px;
    }
  }
}
.tz-conference{
  .row{
    margin-left: 0;
    margin-right: 0;
  }
}
.tz_description.tz-center{

  h3{
    text-transform: uppercase;
    font-size: 26px;
  }
  em{
    text-transform: uppercase;
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    padding-right: 5px;
  }
}

.tz_meetup_title_content{
  p{
    font-size: 16px;
  }
}
.simple:hover{
  .simple-overlay{
    background: rgba(36, 39, 50, 0.9) none repeat scroll 0 0 !important;
  }
}
.simple-overlay{
  background: rgba(0, 0, 0, 0.3) none repeat scroll 0 0 !important;
  opacity: 1 !important;
}
.simple .simple-info a{
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;
  text-transform: uppercase;
}

//mup_3-----------------------------------------------------------------------------------------------------------------
.tz_meetup_btn{
  h4.tz_meetup_title_btn{
    color: #fff;
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 35px;
    text-transform: uppercase;
  }
  a{
    border: 2px solid #000;
    border-radius: 25px;
    color: #242732;
    display: inline-block;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 3px;
    padding: 13px 35px;
    text-decoration: none;
    transition: all 0.2s linear 0s;
    &:hover{
      background: #000 none repeat scroll 0 0;
      color: #fff;
    }
  }
}

.sponsors-item{
  img{
    margin: auto;
    padding: 0 10px;
    text-align: center;
    width: auto;
  }
}
.tz_title_meetup_line{
    font-size: 26px;
    em{
      font-style: normal;
      font-weight: 400;
    }
}
//question-list---------------------------------------------------------------------------------------------------------
.question-list{
  .question-item{
    margin-bottom: 75px;
  }
  .gn-icon{
    background: #e45914 none repeat scroll 0 0;
    border-radius: 50%;
    display: inline-block;
    float: left;
    height: 20px;
    margin-top: 4px;
    text-align: center;
    width: 20px;
    i{
      color: #fff;
      font-size: 18px;
      line-height: 20px;
    }

  }
  h4{
    margin-left: 40px;
    margin-bottom: 20px;
    a{
      font-size: 18px;
      font-weight: 600;

    }
  }
  .gn_desc{
    margin-top: 10px;
    padding-left: 40px;
    p{
      font-weight: 300;
    }
  }

}

h4{
  a{
    color: #242732;
    line-height: 1.4em;
    margin-top: 0;
  }
}

.tz_opacity{
  position: relative;
&:before{
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  position: absolute;
  top: 0;
  right: 0;
  content: "";

}
}
.tz_opacity_1{
  .tz_opacity;
  &:before{
    background: rgba(9, 9, 31, 0.45);
  }
}

.slick-slide{
  padding:  0 15px;
  &:hover{

    .recen_box{
      &:before{
        background: rgba(0, 0, 0, 0.62) none repeat scroll 0 0;
      }
      .mod-recent_link a{
        opacity: 1;
        transform: scale(1);
      }
    }
  }
  .recen_box{
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    &:before{
      background: rgba(0, 0, 0, 0.3) none repeat scroll 0 0;
      content: "";
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      transition: all 0.2s linear 0s;
      width: 100%;
      img{

        width: 100%;
      }
    }
    .mod-recent_link{
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
      a{
        background: rgba(255, 255, 255, 0.2) none repeat scroll 0 0;
        border-radius: 5px;
        color: #fff;
        display: inline-block;
        font-size: 18px;
        margin: 1px;
        opacity: 0;
        padding: 8px;
        text-decoration: none;
        transform: scale(0.5);
        transition: all 0.3s linear 0s;
      }
    }
  }
  .meetup_date{
    background: #e45914 none repeat scroll 0 0;
    border-bottom-right-radius: 5px;
    border-top-left-radius: 5px;
    left: 0;
    padding: 13px 15px;
    position: absolute;
    text-align: center;
    top: 0;
    z-index: 1;
    cursor: pointer;
    .mod-recent-mon{
      color: #fff;
      font-size: 20px;
      text-transform: uppercase;
      display: block;
    }
    .mod-recent-date{
      color: #e5c9bb;
      font-size: 17px;
    }
  }
  .mod_recent_content{
    text-align: left;
    p.mod-articles-category-introtext{
      padding-bottom: 10px;
    }
    .mod-articles-category-title{
      display: block;
      font-size: 15px;
      margin: 25px 0 20px;
      a{
        text-transform: uppercase;
        color: #242732;
        text-decoration: none;
      }

    }
    .mod-recent-writtenby{
      color: #95979d;
      font-size: 12px;
      margin-right: 10px;
      text-decoration: none;
    }
    .mod-recent-date{
      font-size: 12px;
      margin-right: 10px;
      i{
        margin-right: 5px;
      }
    }
  }
}
//recent posts
.tz_meetup_btn_post{
  text-align: center;
  padding-top: 78px;
  .meetup_btn{
    padding: 13px 35px;
  }
}
ul.tz_recent_posts{
  margin-bottom: 80px;
  padding: 0;

  .slick-prev, .slick-next{
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border: 1px solid #b0b2b6;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    &:before{
      color: #b0b2b6;
      font-size: 32px;
    }
  }
  .slick-prev{
    left: -125px;
    .transition(200ms);
  }
  .slick-next{
    right: -125px;
    .transition(200ms);
  }
}
.TzContactInfo{
  display: inline-block;
  margin: 0;
  padding: 99px 0 110px;
  li{
    &:first-child{
      padding-bottom: 20px;
    }
    border-bottom: 1px solid #4f4f4f;
    color: #aaa;
    font-size: 16px;
    list-style: outside none none;
    margin-bottom: 28px;
    padding-bottom: 30px;
    &:last-child{
      border-bottom: medium none;
      padding-bottom: 0;
    }
    .tzContactPhone{
      font-size: 20px;
      line-height: 1.3em;
      margin: 0;
    }
    a{
      color: #e45914;
      text-decoration: none;
    }
    h4{
      color: #ddd;
      font-size: 20px;
      margin-bottom: 5px;
    }
  }
}
//map
.tz_map_meetup{
  position: relative;
}
.tz_meet_gmap_contact{
  background-color:rgba(9,9,31,0.45);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

//widge contact
.contactform{
  margin-bottom: 40px;
  position: relative;
  &:before{
    background: #e45914 none repeat scroll 0 0;
    content: "";
    height: 1px;
    left: 0;
    position: absolute;
    top: 38%;
    width: 33.2%;
  }
  &:after{
    background: #e45914 none repeat scroll 0 0;
    content: "";
    height: 1px;
    position: absolute;
    right: 0;
    top: 38%;
    width: 33.2%;
  }

  .max-email-box{
      margin: 0 auto;
      width: 30%;
  }
  .mc-field-group.control-input{
    input.required.email{
      background: #313543 none repeat scroll 0 0;
      border: 2px solid #1e212a !important;
      border-radius: 30px;
      color: #5e6479;
      font-size: 15px;
      font-style: inherit;
      padding: 25px;
      width: 100%;

      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;

      line-height: 17px;
      margin: 0 -7px 0 0;
      max-width: 100%;

      position: relative;
    }
  }
  .clear input.button{
    border-radius: 30px;
    bottom: 10px;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 3px;
    margin: 0;
    padding: 5px 40px;
    position: absolute;
    right: 10px;
    top: 10px;
    width: auto;
    background: #e45914 none repeat scroll 0 0;
    border: 1px solid #e45914;
    color: #ffffff;
    height: 51px;
    text-transform: uppercase;
    .transition(200ms);
    &:hover{
      background: @cl-white;
      color: #000;
      border-color: @cl-white;
    }
  }

  #mc_embed_signup_scroll{
    position: relative;
  }
}
h3.title_widget{
  padding: 0 0 47px;
  margin: 0;
  span{
    color: #44495c;
    font-size: 36px;
    font-weight: 300;
    line-height: 1.2em;

    text-transform: none;
  }
}
.tz-social{
  text-align: center;
}
.tzSocial_bg{
  .tzSocial_bg_meetup{
    display: inline-block;
    position: relative;
    &:after{
      background: #4c5266 none repeat scroll 0 0;
      height: 1px;
      left: 100%;
      position: absolute;
      top: 50%;
      content: "";
      width: 25px;
    }
    &:before{
      background: #4c5266 none repeat scroll 0 0;
      height: 1px;
      position: absolute;
      content: "";
      right: 100%;
      top: 50%;
      width: 25px;
    }
    a.tzSocial-no {
      background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
      display: inline-block;
      height: auto;
      padding: 0 10px;
      text-align: center;
      transition: all 0.2s linear 0s;
      width: auto;
    }
    a.tzSocial-no i {
      .transition(200ms);
        color: #495266;
        font-size: 20px;
      &:hover{
        color: @cl-orange;
      }
    }
  }
}
.tzcopyright{
  text-align: center;
  p{
    color: #656a7e;
    font-family: Lato,sans-serif;
    font-size: 12px;
    font-weight: 400;
    margin: 0 0 3px;
    text-transform: uppercase;
    a{
      color: #787e92;
    }
  }
}
.close{
  font-size: 50px;
}

.tztwd-avatar{
  width: auto;
}
.tztwd::before{
  position: relative !important;
}
.tztwd-container{
  ul{
    padding: 0;
  }
  .tzTwitter-icon{
    i{
      color: #fff;
      font-size: 40px;
    }
  }
  .tweets_txt{
    display: block;
    text-align: center;
    color: #eee;
    font-size: 30px;
    font-weight: 300;
    line-height: 1.6em;
    padding: 0 20%;
    a{
      color: @cl-orange !important;
    }
    span{
     padding-top: 35px;
      display: block;
      color: #eee;
      font-size: 15px;

    }
  }
}
///register
.jsn-text-center{
  display: none;
}
.tz_register_meetup_pricing {
  margin-left: 30px;
  margin-right: 68px;
}
.tz_register_meetup{
  margin: 0 0 0 auto;
  width: 585px;
  text-align: left;
}
.tz_register_meetup_pricing_item{
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 15px;
  position: relative;
  transition: all 0.3s linear 0s;

  .activer{
    .tz_register_meetup_pricing_item_container {
      background: #e45914 none repeat scroll 0 0;
      h3,p{
        color: #fff;
      }
    }

  }
  &:hover{
    .tz_register_meetup_pricing_item_container{
      background: #e45914 none repeat scroll 0 0;
      p,h3{
        color: #fff;
      }
    }
  }
  &:after{
    clear: both;
    content: "";
    display: table;
  }
  :last-child {
    margin-bottom: 0;
  }

}
.tz_register_meetup_pricing_item_container{
  background: #fff none repeat scroll 0 0;
  border-radius: 5px 0 0 5px;
  float: left;
  padding: 21px 20px;
  transition: all 0.3s linear 0s;
  width: 70%;
  h3{
    color: #5c5e63;
    font-size: 20px;
    font-weight: 300;
    margin-bottom: 10px;
  }
  p{
    line-height: 1.6em;
    margin: 0;
  }
}

.tz_register_meetup_pricing_item_price {
  background-size: cover;
  border-radius: 0 5px 5px 0;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 30%;
  h3{
    color: #fff;
    font-size: 40px;
    font-weight: 500;
  }
}
.tz_register_meetup_pricing_item.active {
  z-index: 1;
  .tz_register_meetup_pricing_item_container{
    background: #e45914 none repeat scroll 0 0;
  }
}
.tz_register_meetup_pricing_item.active .tz_register_meetup_pricing_item_container h3, .tz_register_meetup_pricing_item.active .tz_register_meetup_pricing_item_container p{
  color: #ffffff;
}
.tz_register_meetup_pricing_item.active .tz_register_meetup_pricing_item_price::after{
  border-bottom: 25px solid transparent;
  border-left: 25px solid #e45914;
  border-top: 25px solid transparent;
  content: "";
  margin-top: -22px;
  position: absolute;
  right: -15px;
  top: 50%;
  z-index: -1;
}
.jsn-master{
  line-height: 1.85em !important;
  .jsn-bootstrap{

    .tz_title_1.register_title{
      font-size: 26px;
      margin: 0 0 40px;
      padding-bottom: 30px;
      text-transform: uppercase;
      font-weight: 700;
      em{
        font-style: normal;
        font-weight: 400;
      }
      &:after{
        position: absolute;
      }
    }
    .tz_meetup_title_content{
      padding-bottom: 33px;
      p{
        line-height: 25.9px;
        margin: 0;
      }
    }
  }
}
.jsn-master .controls .jsn-input-medium-fluid{

  background: rgb(255, 255, 255) none repeat scroll 0 0;
  border: 2px solid rgb(255, 255, 255);
  border-radius: 5px;
  color: rgb(83, 88, 107);
  font-size: 15px;
  line-height: 1.4em;
  padding: 13px 20px;
  transition: all 0.3s linear 0s;
  width: 100%;
}
#jsn_form_2.jsn-uniform.jsn-master{
  padding: 98px 182px 95px 85px;
  .jsn-bootstrap .jsn-form-content .control-group{
    padding: 0 !important;
    margin-bottom: 15px !important;
    &:last-child{
      margin-bottom: 62px !important;
    }
    .control-label{
      color:  rgb(126, 131, 148) !important;
      font-size: 13px;
      font-weight: 500;
      margin-bottom: 9px;
      text-transform: uppercase;
      span{
        color:  rgb(126, 131, 148) !important;
      }
    }
  }
}
#jsn_form_2.jsn-master.jsn-uniform .jsn-bootstrap .jsn-form-content .control-group.ui-state-highlight{
  background: transparent !important;
  border-color: transparent !important;
}

.fixed-top{
  background: rgba(0, 0, 0, 0.7) none repeat scroll 0 0 !important;
}
.tz-title.tz-center.tz_title_meetup_normal > h3{
  font-weight: 400;
}
//footer
.relative_footer{
  position: relative;
  .absolute_footer{
    position: absolute;
    top: -43px;
    right: 13em;
    z-index: 1;
    i{
      color: @cl-white;
      border: 1px solid @cl-orange;
      background: @cl-orange;
      width: 50px;
      border-radius: 10px;
      height: 50px;
      padding-left: 9px;
      padding-top: 3px;
      font-size: 40px;
      cursor: pointer;
    }
  }
}

.tz_opacity_1 {
  background-position: center;
  background-size: cover;
}
.sponsors-list{
  .slick-slider{
    cursor: w-resize;
  }
}

.style_top{
  background: @cl-orange;
  .tz_meetup_header_option_phone{
    span{
      color: rgb(255, 255, 255);
      display: inline-block;
      line-height: 2.5em;
      &:first-child{
        margin-right: 40px;
      }
      img{
        margin-right: 5px;
        margin-left: -5px;
      }
      a{
        color: rgb(255, 255, 255);
        text-decoration: none;
      }
    }
  }
}
.style_top{
  .container{
    padding-left: 15px;
    padding-right: 15px;
  }
}
//top right
.tzSocial_bg_meetup_style2{
  text-align: right;
  ol li, ul li{
    padding: 5px 0;
  }
  ul{
    display: inline;
    float: right;
    margin: 0;
    padding: 0;
    li{
      float: left;
      &:first-child{
        a{
          padding-right: 2px;
        }

      }
      a{
        display: inline-block;
        padding: 0 14px;

        i{
          color: rgb(255, 255, 255);
          font-size: 14px;
        }
      }
    }
  }
}
//menu deflault
.navbar-default{
  background-color: #ffffff;
  .plazart-megamenu{
    ul{
      > li{
        > a{
          font-size: 12px;
          padding: 0;
          color: rgb(36, 39, 50);
          .caret{
            display: none !important;
          }
          &:before{
            bottom: -4px;
          }
        }
      }
    }
  }
  .navbar-nav > .active > a, .navbar-nav > .active > a:hover, .navbar-nav > .active > a:focus{
    background: transparent;
    color: rgb(36, 39, 50);
  }
  .row{
    position: relative;
  }
  .navbar-collapse{
    padding: 0;
  }

}





.breadcrumb{
  margin-bottom: 0;
  padding: 68px 0;
  background-color: transparent;
  a{
    span{
      font-weight: 400;
    }
  }
  .left_breadcrumbs{
    display: inline-block;
    h4{
      color: rgb(255, 255, 255);
      font-size: 18px;
      font-weight: 400;
      line-height: 1.7em;
      text-transform: uppercase;
      margin: 0;
    }
  }
  .right_breadcrumbs{
    text-align: right;
    .active,.link_home{
      display: inline-block;
      color: #fff;
    }
    .link_home{

      a{
        padding: 0;

        color: #fff;
        &:hover,&:focus{
          color: #fff;
        }
      }
    }
  }
}
//Speakers++____________________________________________________________________________________________________________

span.tz_text_hover_speaker {
  background: #ffffff none repeat scroll 0 0;
  border-radius: 25px;
  color: #e45914;
  display: inline-block;
  font-size: 12px;
  font-weight: 400;
  line-height: 2.5em;
  opacity: 0;
  padding: 0 25px;
  text-transform: uppercase;
  transform: translateY(30px);
  transition: all 0.4s ease 0s;
}
img.media-object{
  width: 100%;
}
.tz_our_speakers_thumbnail{
  margin:0 7px 43px 7px;
  &:hover{
    .tz_text_hover_speaker {
      opacity: 1;
      transform: translateY(0px);
      transition: all 0.4s linear 0.1s;
    }
    .tz_thumbnail .tz_social{
      opacity: 1;
      transform: translateY(0px);
      transition: all 0.4s ease 0.5s;
    }
    .tz_our_speakers_container{
      border-bottom-color: rgb(228, 89, 20);
    }
    .tz_thumbnail{
       &::after{
        background: rgba(228, 89, 20, 0.8) none repeat scroll 0 0;
        transition: all 0.4s linear 0s;
      }
    }
  }
}
.tz_our_speakers_container {
  border-bottom: 5px solid;
  border-left: 1px solid;;
  border-right: 1px solid;
  border-color: rgb(231, 231, 231);
  padding: 14px 0 15px;
  text-align: center;
  transition: all 0.4s linear 0s;
  h4{
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 2px;
  }
  span{
    color: rgb(170, 170, 170);
    font-size: 12px;
  }
}
.position_center{
  background-position: center center;
}
.slick-slider{
  margin-bottom: 0 !important;
}
.manufacturers{

  .slick-prev, .slick-next{
    height: 50px;
    &:hover,&:focus{

      &:before{

        color: rgb(228, 89, 20);
        outline: medium none;
      }
    }
    &:before{

      color: rgb(170, 170, 170);
      font-size: 60px;
    }
  }
}


//+ blog----------------------------------------------------------------------------------------------------------------
.blog{
  .items-row{
    margin-bottom: 40px;

    i.fa-comments{
      display: none;
      &:before{
        content: "";
      }
    }
    .item.column-1{
      background: rgb(248, 248, 249) none repeat scroll 0 0;
      border-bottom: 2px solid rgb(228, 89, 20);
      padding: 30px;
    }
  }
  .jcomments-links{
    text-align: right;
  }
  .readmore-link{
    color: #242732;
    font-size: 11px;
    font-weight: 600;
    letter-spacing: 2px;
    text-align: right;
    text-transform: uppercase;
    display: inline-block;
    background-image: none;

  }
  .blog_wrap{
    .item-image{
      position: relative;
      border-radius: 5px;
      margin-bottom: 32px;
      overflow: hidden;
      img{
        display: block;
        height: auto;
        margin: 0 auto;
        max-width: 100%;
        width: 100%;
      }
      &:hover{
        a.tz-ViewImage{
          opacity: 1;
          top: 50%;
          transition: all 0.3s ease-in 0s;
          visibility: visible;
        }
        a.tz-ViewDetail{
          opacity: 1;
          top: 50%;
          transition: all 0.3s ease-in 0.1s;
          visibility: visible;
        }
        .ImageOverlayH{
          opacity: 1;
          transform: scale(1);
        }
      }
      .ImageOverlayH{
        background-color: rgba(0, 0, 0, 0.4);
        height: 100%;
        opacity: 0;
        position: absolute;
        right: 0;
        top: 0;
        transform: scale(0.7);
        transition: all 0.3s ease-in-out 0s;
        width: 100%;
        a.tz-ViewImage{
          left: 50%;
          margin-left: -44px;
        }
        a.tz-ViewDetail{
          margin-right: -44px;
          right: 50%;
        }
        a{
          background-color: rgb(255, 255, 255);
          border-radius: 50%;
          height: 40px;
          margin-top: -20px;
          opacity: 0;
          position: absolute;
          text-align: center;
          top: 30%;
          visibility: hidden;
          width: 40px;
          i{
            color: rgb(0, 0, 0);
            padding: 14px 0;
          }
        }
      }
    }
  }
  .block_intro{
    p{
      color: #646569;
    }
    .page-header{
      border-bottom: 1px solid rgb(238, 238, 238);
      margin: 40px 0 20px;
      padding-bottom: 9px;
    }
    h2{
      font-size: 30px;
      font-weight: 600;
      line-height: 1.2em;
      margin: 0 0 8px;
      padding: 0;
      a{
        color: rgb(36, 39, 50);
      }
    }
    .page-header{
      border: 0;
      margin:  0;
      padding-bottom: 0;
    }
  }
  .article-info{
    dt {
      border-right: 1px solid #cccccc;
      color: #95979d;
      display: inline-block;
      line-height: 1.1;
      margin-left: 10px;
      margin-right: 0;
      padding-right: 10px;
      white-space: nowrap;
      font-weight: 300;
    }
    dt:first-child{
      margin-left: 0;
    }
    dt,.comments-link,dd{
      color: rgb(149, 151, 157);
      font-size: 14px;
    }
    dd{
      border-right: 1px solid rgb(204, 204, 204);
      display: inline-block;
      line-height: 1.1;
      margin-left: 10px;
      padding-right: 10px;
      margin-right: 0;
      white-space: nowrap;
      color: rgb(149, 151, 157);
      a{
        color: rgb(149, 151, 157);
        &:hover{
          color: rgb(149, 151, 157);
        }
      }
      &:first-child{
        padding-left: 0;
        border-left: 0;
      }
    }
  }
  .readmore{
    text-align: right;
    > .btn{
      background-color: transparent;
      border-color: transparent;
      color: rgb(36, 39, 50);
      font-size: 11px;
      font-weight: 600;
      letter-spacing: 2px;
      text-transform: uppercase;
      .transition(200ms);
      &:hover{
        color: @cl-orange;
      }
    }
  }
}
.btn:focus, .btn:active:focus, .btn.active:focus, .btn.focus, .btn.focus:active, .btn.active.focus{
  outline: none;
  outline-offset: 0;
}
// ++module seach-------------------------------------------------------------------------------------------------------
.tz-module{
  .search{
    .form-search{
      position: relative;
      button{
        color: rgb(190, 195, 204);
        display: block;
        margin-top: -6px;
        position: absolute;
        right: 0;
        top: 50%;
        transform: rotate(90deg);
        background: transparent none repeat scroll 0 0;
        border: 0 none;
      }
    }
    input{
      border: 0;
      border-bottom: 1px solid rgb(218, 224, 234);
      line-height: 30px;
      width: 100%;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0;
      box-shadow: none;
      background: transparent;
      color: rgb(17, 17, 17);
      font-family: Raleway,sans-serif;
      font-size: 14px;
      font-style: italic;
      font-weight: 400;
      padding: 15px 50px 15px 19px;
      height: 50px;
    }
  }
}
.tz-module.search_speakers{
  .search{
    input{
      padding: 0;
      height: 34px;
      font-family: "Roboto Slab", serif;
    }
    .form-search{
      i{
        margin-top: -8px;
      }
    }
  }
}
h3.module-title{
  position: relative;
  span{
    font-size: 20px;
    font-weight: 600;
  }
  &::before{
    background: rgb(228, 89, 20) none repeat scroll 0 0;
    bottom: 0;
    content: "";
    height: 2px;
    left: 0;
    position: absolute;
    width: 25px;
  }
}

// ++Social Blog--------------------------------------------------------------------------------------------------------

.Social_blog_bg_meetup{
  ul{
    padding: 0;
    li{
      display: inline-block;
      a{
        color: #fff;
        border-radius: 3px;
        display: inline-block;
        height: 29px;
        margin-bottom: 5px;
        padding: 5px 0;
        text-align: center;
        text-decoration: none;
        width: 29px;
        i{
          text-align: center;
        }
      }
    }
  }
}

//++ Categories-Blog----------------------------------------------------------------------------------------------------
ul.categories-module{
  padding: 0;
  ul{
    padding: 0;
    >li{
      &:last-child{
        border-bottom: 1px solid rgb(221, 221, 221);
      }
    }
    h5{
      font-weight: 400;
      a{
        border-bottom: medium none;
        color: rgb(100, 101, 105);
        padding-bottom: 0;
      }
    }
  }

    li:nth-child(2){
      ul{
        li{
          h5:last-child{
            margin-bottom: 16px;
          }
        }
      }
      h4{
        a{
          padding-bottom: 10px;
        }
      }
      a{
        border-bottom: 0;
      }
    }
    li{
      color: rgb(36, 39, 50);
      font-weight: 500;
      list-style: outside none none;
      h4, .h4{
        margin-bottom: 0;
      }
      a{
        border-bottom: 1px solid rgb(221, 221, 221);
        color: rgb(36, 39, 50);
        display: block;
        list-style: outside none none;
        padding: 11px 0;
        text-decoration: none;
        text-transform: capitalize;
        .transfrom(0.3s);
        font-size: 14px;
        &:hover{
          color: @cl-orange;
        }
      }
    }
}
//++ Tag-Blog-----------------------------------------------------------------------------------------------------------
.tagspopular{
  >ul{
    >li{
      >a{
        background: rgb(80, 82, 91) none repeat scroll 0 0;
        border-radius: 3px;
        color: rgb(197, 198, 200);
        display: inline-block;
        font-family: Lato,sans-serif;
        font-size: 14px !important;
        margin-bottom: 5px;
        margin-right: 2px;
        padding: 5px 12px;
        text-decoration: none;
        .transfrom(300ms);
        &:hover{
          background: @cl-orange;
        }
      }
    }
  }
}
//++ Archive Blog-------------------------------------------------------------------------------------------------------
ul.archive-module{
  padding: 0;
  li{
    border-bottom: 1px solid rgb(221, 221, 221);
    font-weight: 500;
    color: rgb(36, 39, 50);
    list-style: outside none none;
    a{
      border: medium none;
      color: rgb(100, 101, 105);
      display: inline-block;
      list-style: outside none none;
      padding: 6px 0 12px;
      text-decoration: none;
      text-transform: capitalize;
      transition: all 0.3s ease-in-out 0s;
    }
  }
}

//++ Flickr Blog-------------------------------------------------------------------------------------------------------

#tz-flickr{
  ul.flickr{
    padding: 0;
    li{
      &:hover{
        a{
          text-decoration: none;
          color: #e45914;
        }
      }
      a{
        position: relative;
        text-decoration: none;
        display: block;
        border-bottom: 1px solid #ddd;
        color: #242732;
        list-style: none;
        text-transform: capitalize;
        .transition(300ms);
        &:hover{
          &:before{
            opacity: 1;
            .transfrom(scale(1));
          }
          &:after{
            opacity: 1;
          }
        }
        &:before{
          content: "\f002";
          position: absolute;
          top: 50%;
          left: 50%;
          margin-top: -14px;
          margin-left: -14px;
          z-index: 1;
          font-size: 11px;
          width: 28px;
          height: 28px;
          color: #fff;
          border-radius: 50%;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          -ms-border-radius: 50%;
          -o-border-radius: 50%;
          background: rgba(36,39,50,.6);
          padding: 2px 9px;
          opacity: 0;
          .transition(400ms);
          .transfrom(scale(.5));
        }
        &:after{
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(228,89,20,.9);
          opacity: 0;
          .transition(400ms);
        }
      }
    }
  }
}

//++  Twitter Blog------------------------------------------------------------------------------------------------------

.tztwd_blog_container{
  .tweets_txt{
    padding-bottom: 15px;
    padding-left: 43px !important;
    position: relative;
    border-bottom: 1px solid #dce2ed;
    margin: 0 0 14px;
  }
  ul{
    li{
      position: relative;
      a{
        color: @cl-orange !important;
      }
      &:after{
        position: absolute;
        content: "\f099";
        font-size: 20px;
        background: #e45914;
        color: #fff;
        top: 16px;
        left: 0;
        width: 27px;
        height: 27px;
        text-align: center;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
        line-height: 1.3em;

      }
    }
  }
}

//++ Pagination --------------------------------------------------------------------------------------------------------
ul.pagination{
  li{
    a{
      margin: 0 3px;
    }

  }
}
.blog{
  div.pagination{
      float: right;
    p.counter.pull-right{
      border: 1px solid rgb(221, 221, 221);
      color: rgb(0, 0, 0);
      display: inline-block;
      font-family: Lato,sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      padding: 10px 15px;
      text-decoration: none;
      .transition(200ms);
      float: left !important;
      &:hover{
        background: @cl-orange;
        color: #fff;
        border-color: @cl-orange;
      }
    }
    li{
      a{
        border: 1px solid rgb(221, 221, 221);
        color: rgb(0, 0, 0);
        display: inline-block;
        font-family: Lato,sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        padding: 10px 15px;
        text-decoration: none;
        .transition(200ms);
        &:hover{
          background: @cl-orange;
          color: #fff;
          border-color: @cl-orange;
        }
      }
      &.active{
        a{
          background: @cl-orange;
          color: #fff;
        }
      }
      &.disabled{
        display: none;
      }
    }
  }
}
.lnk_berum{
  line-height: 20px;
  span{
    color: rgb(255, 255, 255);
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    text-transform: uppercase;
  }
}
//+ Articles -----------------------------------------------------------------------------------------------------------
.tz-conten-blog{
  background: rgb(248, 248, 249) none repeat scroll 0 0;
  border-radius: 5px;
  overflow: hidden;
  padding: 40px 40px 0;
  .tz-blogtext-content{
    .Title_articles{
      h2{
        font-size: 30px;
        font-weight: 600;
        margin: 0 0 8px;
        padding: 0;
      }
    }
    p{
      color: rgb(100, 101, 105);
      line-height: 1.8em;
      margin-bottom: 25px;
    }
  }

  .tz_conten_img{
    img{
      width: 100%;
    }
  }
}
//++ button share_______________________________________________________________________________________________________
.entry-single-comment{
  border: 1px solid #dce2ed;
  border-radius: 25px;
  color: #95979d;
  display: inline-block;
  line-height: 6px;
  margin-right: 5px;
  padding: 11px 15px 5px;
  p.count,i{
    display: inline-block;
  }
}
.tz-conten-blog{
  position: relative;
}
.share-wrap {
  display: inline-block;
  float: right;
  p {
    border: 1px solid rgb(220, 226, 237);
    border-radius: 25px;
    color: rgb(149, 151, 157);
    cursor: pointer;
    display: inline-block;
    font-family: Lato,sans-serif;
    padding: 5px 15px;
  }
  .share-wrap-content {
    position: absolute;
    transform: scale(0);
    transform-origin: right center 0;
    transition: all 0.3s linear 0s;
    width: 100%;
    bottom: -20px;
    right: 0;
    a{
      border-radius: 50%;
      color: rgb(255, 255, 255);
      display: inline-block;
      height: 40px;
      line-height: 40px;
      text-align: center;
      width: 40px;
    }
  }
  .share-wrap-content.share-wrap-full{
    transform: scale(1);
  }
}
.bt_facebook{
  background: rgb(67, 150, 230) none repeat scroll 0 0;
}
.bt_twitter{
  background: rgb(21, 192, 204) none repeat scroll 0 0;
}
.bt_google{
  background: rgb(76, 88, 102) none repeat scroll 0 0;
}
.bt_pinterest{
  background: rgb(245, 96, 72) none repeat scroll 0 0;
}
.tb_linkedin{
  background: rgb(80, 82, 91) none repeat scroll 0 0;;
}

.tz_SingleContentBox_like{
  text-align: right;
  position: relative;
  padding: 20px 0 26px;
}
//++ Info Article_______________________________________________________________________________________________________
.Info_article{
  .article-info{
    dd{
      padding-right: 5px;
      position: relative;
      margin-right: 0;
      padding-left: 10px;
      color: rgb(149, 151, 157);
      a{
        color: rgb(149, 151, 157);
      }
      &:first-child{
        padding-left: 0;
      }
      &:before{
        content: "";
        background: rgb(149, 151, 157);
        height: 15px;
        width: 1px;
        position: absolute;
        top: 0;
        right: 0;
      }
      &:last-child{
        &:before{
          width: 0;
        }
      }
    }
    span{
      dd{
        padding-right: 10px;
        &:first-child{
          padding-left: 10px;
        }
        &:before {
          .transfrom(rotate(25deg));
        }
        &:last-child{
          &:before{
            width: 1px;
          }
        }
      }
    }
  }
}

.pagenav{
  li{
    a{
      background: rgb(80, 82, 91) none repeat scroll 0 0;
      color: #fff;
      border-color: rgb(80, 82, 91);
      .transition(300ms);
      &:hover{
        background: @cl-orange;
        border-color: @cl-orange;
      }
    }
  }
}
//++ Comment____________________________________________________________________________________________________________

#jc #comments-form input:focus, #jc #comments-form textarea:focus, #jc #comments-form input:hover, #jc #comments-form textarea:hover, #jc #comments-form input:active, #jc #comments-form textarea:active {
  border: 1px solid @cl-orange;
  outline-color: @cl-orange;
}
#jc {
  .tz-comment {
    position: relative;
    :last-child{
      margin-bottom: 50px;
    }
    .fa {
      color: rgb(170, 170, 170);
      pointer-events: none;
      position: absolute;
      right: 25px;
      top: 13px;
    }
  }
  #comments{
    .avatar-indent {
      margin: 0;
      padding-left: 140px;
    }
  }
  #comments-form{
    margin: 0 -15px;
    span{
      input {
        margin: 0;
      }
    }
    input#comments-form-subscribe {
      display: inline-block;
      width: auto !important;
    }
    #comments-form-buttons{
      float: left ;
      margin: 24px 0;
      margin-left: 0 !important;
      .btn{
        margin-right: 0;
        div {
          background: @cl-orange none repeat scroll 0 0;
          border-color: @cl-orange;
          float: left;
          margin: 0 -1px 0 0;
          padding: 14px 15px;
          border-radius: 3px;
          transition: all 300ms ease 0s;
        }
        div:hover {
          background: rgb(80, 82, 91)  none repeat scroll 0 0;
          border-color: rgb(80, 82, 91) ;
        }
        div:hover a {
          border-color: rgb(37, 37, 37);
          color: rgb(255, 255, 255);
        }
        div a {
          color: #ffffff;

        }
      }
    }

    .grippie {
      background: rgb(255, 255, 255) none repeat scroll 0 0;
      border-top: 0 none;
      display: none;
      max-width: 100% !important;
      width: 100%;
    }
    input,textarea {
      background-color: rgb(248, 248, 249);
      border: 1px solid rgb(255, 255, 255);
      border-radius: 3px;
      color: rgb(101, 101, 101);
      display: block;
      font-size: 14px;
      height: 42px;
      line-height: 1.42857;
      margin-bottom: 30px;
      padding: 12px 22px !important;
      vertical-align: middle;
      width: 100%;
    }
    p {
      margin-bottom: 0;
      margin-left: auto !important;
      margin-right: 0;
      margin-top: 0;
      textarea {
        height: 180px;
        margin: 0;
        max-width: 100% !important;
      }
    }
  }
  h4 {
    border: 0 none;
    color: #3a424a;
    font-size: 30px;
    font-weight: 600;
    margin: 50px 0 35px;
    padding: 0;
  }
  .comments-list{
    position: relative;
    margin-left: 0;
    img {
      border: 2px solid rgb(212, 212, 212) !important;
      border-radius: 50%;
      left: 28px;
      margin-top: -53px;
      position: absolute;
      top: 50%;
      width: 97px;
    }
  }
  .comment-body{
    img{
      border: 0;
      border-radius: 0;
      position: relative;
      margin-top: 0;
      width: auto;
    }
  }
  .rbox {
    border: 0 ;
    border-radius: 0;
    padding: 36px 15px;
    background: rgb(248, 248, 249) none repeat scroll 0 0;
    border-bottom: 5px solid #f2f2f2;
    border-radius: 5px;
    div {
      font-size: 13px;
      line-height: 1.7;
      margin-top: 15px;
      text-align: left;
    }
    .comments-buttons {
      text-align: left;
    }
  }
}
#comments .even{
  margin-bottom: 30px;
}
.clean_fix_comments{
  position: relative;
}
#comments-footer{
  display: none;
}
//+ Blog Masonry________________________________________________________________________________________________________
.masonry{
  padding: 100px 0;
  .article-info .comments-link{
    font-size: 12px;
  }
  .grid{
    margin-left: -15px;
    margin-right: -15px;
  }
  .page-header{
    h2{
      a{
        color: rgb(17, 17, 17);
        font-size: 15px;
        font-weight: 400;
        line-height: 18px;
      }
    }
  }
  .blog_wrap{
    .item-image{
      border-radius: 2px;
      margin-bottom: 0;
    }
  }
  .block_intro{
    border-bottom: 2px solid rgb(228, 89, 20);
    border-right: 1px solid rgb(221, 221, 221);
    border-left: 1px solid rgb(221, 221, 221);
    padding: 15px 18px 20px;
    h2,dl{
      line-height: 10px;
    }
    dl{
      margin-bottom: 0;
    }
    h2{
      margin: 5px 0 8px;
    }
    p{
      color: rgb(68, 68, 68);
      font-size: 14px;
      font-weight: 300;
      line-height: 24px;
      margin: 0 0 15px;
    }
  }
}
.wrapper_date{
display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}
dt.day,dd.month{
  display: block;
}

.date_on_corner{
  display: inline-block;
  background: rgb(228, 89, 20) none repeat scroll 0 0;
  height: 50px;
  padding: 5px;
  width: 50px;
  dt.day{
    color: rgb(255, 255, 255);
    display: block;
    font-family: Lato,sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
  }
  dd.month{
    color: rgb(255, 255, 255);
    display: block;
    font-family: Lato,sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 15px;
    text-align: center;
    text-transform: uppercase;
  }
}
.blogIcon_masonry{
  background: rgb(68, 68, 68) none repeat scroll 0 0;
  height: 50px;
  text-align: center;
  width: 50px;
  i{
    color: rgb(255, 255, 255);
    font-size: 16px;
    padding: 16px;
  }
}
.relative_item{
  position: relative;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  margin: 0 15px 30px 15px;
}
.paginnation_masonry.pagination{
  float: none!important;
  display: inline-block ;
  .counter.pull-right{

  }
}
.counter {
  margin: 20px 0;
}
.wrapper_pagination{
  text-align: center;
  display: block;
}
.block_intro{
  ul.article-info{
    margin-bottom: 10px;
    padding: 0;
    display: flex;
    clear: both;
    li{
      float: left;
      padding-right: 10px;
      color: rgb(121, 121, 126);
      font-size: 12px;
      font-style: italic;
      font-weight: 300;
      line-height: 20px;
      margin-right: 5px;
    }
  }
}
.comments-link{
  line-height: 18px;
  color: rgb(121, 121, 126);
  padding: 0 5px 5px 7px;
  font-size: 12px;
  font-style: italic;
  font-weight: 300;
  margin-right: 5px;
  background-image: none;

}
.comments-link,.readmore-link{
  .transition(300ms);
  &:hover{
    color: @cl-orange;
  }
}
.masonry .readmore-link{
  background-image: none;
  color: rgb(66, 72, 77);
  display: block;
  font-family: "Roboto Slab",serif;
  font-size: 11px;
  font-weight: 400;
  letter-spacing: 2px;
  line-height: 24px;
  text-align: right;
  text-decoration: none;
  text-transform: uppercase;
}
//Our speakers sidebar__________________________________________________________________________________________________
.multicolor-subscribe-form{
  .mcolor-subbox{
    background: rgb(248, 248, 249) none repeat scroll 0 0;
    border: medium none !important;
    box-shadow: none;
    color: rgb(191, 200, 211);
    font-style: italic;
    border-radius: 0;
    line-height: 17px;
    margin: 0 -7px 0 0;
    max-width: 100%;
    padding: 9px 25px 9px 8px;
    position: relative;
    width: 100%;
  }
  .mcolor-button{
    border-radius: 3px;
    font-weight: 700;
    margin-top: 10px;
    position: static;
    text-transform: uppercase;
    width: 100%;
    bottom: 0;
    cursor: pointer;
    display: inline-block;
    float: none;
    line-height: 19px;
    padding: 8px 10px;
    right: 0;
    text-decoration: none;
    transition: all 0.5s ease 0s;
    vertical-align: bottom;
    box-sizing: border-box;
    font-family: "lora","serif";
    font-size: 14px;
  }
}
//From contact _________________________________________________________________________________________________________
.form-horizontal .control-label{
  text-align: left;
}
.tz_wpadd{
  margin-bottom: 27px;
  .cont-tt{
    padding-left: 65px;
  }
  a{
    color: rgb(51, 51, 51);
  }
  .all-icadd{
    h4{
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 2.5px;
      margin-left: 65px;
      text-transform: uppercase;
    }



    span{
      border-radius: 50%;
      display: inline-block;
      float: left;
      height: 48px;
      text-align: center;
      width: 48px;
      i.fa{
        color: rgb(255, 255, 255);
        font-size: 24px;
        line-height: 48px;
      }
    }
  }
}

.icon-addressz{
  span{
    background: rgb(59, 134, 207) none repeat scroll 0 0;
  }
}
.icon-phonez{
  span{
    background: #c22d81;
  }
}

.icon-emailz{
  span{
    background:#24aab3;
  }
}
.contact_form{
  p{
    position: relative;
    display: block;
    margin-bottom: 20px;
    i.fa{
        color: rgb(204, 209, 219);
        font-size: 20px;
        margin-top: -10px;
        position: absolute;
        right: 10px;
        top: 50%;
    }
  }
  p.textarea{
    i.fa{
      top: 15%;
    }
  }
  input,textarea{
    box-shadow: none;
    background: rgb(248, 248, 248) none repeat scroll 0 0;
    border: medium none;
    border-radius: 3px;
    color: rgb(149, 151, 157);
    font-weight: 600;
    padding: 15px 10px;
    width: 100%;
    font-size: inherit;
    line-height: inherit;
    height: auto;

  }
}
.nav-tabs{
  border-bottom: 0;
}

.send_email_button{
  background: rgb(230, 100, 35) none repeat scroll 0 0;
  border: medium none;
  border-radius: 3px;
  color: rgb(255, 255, 255);
  font-family: Raleway,sans-serif;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 2px;
  padding: 16px 10px;
  text-transform: uppercase;
  width: 100%;
}
.fucontainer{
  .container-fluid{
    padding-left: 0;
    padding-right: 0;
    .row{
      margin-left: 0;
      margin-right: 0;
    }
  }
}
.mirror.pgthumb .back{
  background: @cl-orange;
}

.pgthumb.rolly{
  .rolly-overlay{
    background: rgba(228, 89, 20, 0.6) none repeat scroll 0 0;
  }
}
.vc_btn3-container{
  .meetup_btn{
    float: left;
    letter-spacing: 0;
    line-height: normal;
    font-weight: 300;
    position: relative;
    i{
      display: block;
      position: absolute;
      top: 50%;
      transform: translate(0px, -50%);
      left: 20px;
    }
  }
}
.wpb_text_column{
  margin-bottom: 30px !important;
}
.vc_btn3-container{
  display: inline-block;
  margin-bottom: 22px;
}




//tag++_________________________________________________________________________________________________________________
.tag-category{
  padding: 50px 114px;
}
.filters.btn-toolbar{
  padding: 20px 0;
  .chzn-container{
    line-height: 20px;
  }
}
//com_search++__________________________________________________________________________________________________________
.search{
  .input-group.form-group{
    .form-control{
      height: 40px;
    }
    .btn{
      background: @cl-orange;
      color: #ffffff;
      border: 1px solid @cl-orange;
    }
  }
  .phrases,.only{
    legend{
      padding-bottom: 10px;
    }
  }
}

//form login++__________________________________________________________________________________________________________
.login {
  margin: auto;
  background: #f7f7f7;
  padding: 30px;
  .btn.btn-primary,button.btn-primary{
    background: @cl-orange none repeat scroll 0 0;
    border: 1px solid @cl-orange;
  }
  .tz-recovery {
    margin-top: 20px;
    ul {
      text-align: left;
      display: inline-block;
    }
  }
  .inputbox {
    display: inline-block;
    width: auto;
    margin: 15px 10px 0;
  }
  .form-horizontal{
    .form-group {
      margin-left: 0;
      margin-right: 0;
      text-align: left;
      display: inline-block;
    }
  }
  input {
    width: 100%;
    border: 1px solid #e7e7e7;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    padding: 15px 20px;
    color: #818d9a;
    font-size: 14px;
    line-height: 1em;
    background-color: #ffffff;
    &:focus {
      outline-color: @cl-orange;
    }
  }
  .login_tille h3 {
    font-size: 30px;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
    color: inherit;
    text-align: center;
    margin-bottom: 20px;
  }
  .btn {
    width: 100%;
    -webkit-transition: all 500ms ease 0s;
    -moz-transition: all 500ms ease 0s;
    -o-transition: all 500ms ease 0s;
    transition: all 500ms ease 0s;
    padding: 12px 12px;
    &:hover {
      background-color: #000000;
      border-color: #000000;
    }
  }
  .controls {
    display: inline;
  }
  .checkbox {
    input {
      width: auto;
    }
  }
  .control-label {
    display: inline-block;
    text-align: left;
    label {
      margin-bottom: 10px;
      font-weight: 400;
    }
  }
  .border-radius(2px);
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  ul {
    list-style-type: none;
    li{
      a {
        padding: 0;
        color: #666;
      }
    }
  }
}


.login .nav .open > a,
.login .nav .open > a:focus,
.login .nav .open > a:hover {
  background-color: transparent;
}

.registration .well {
  background: #4f89af;
  background: -moz-linear-gradient(top, #4f89af 0%, rgba(144, 164, 168, 0.8) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, #4f89af), color-stop(100%, rgba(144, 164, 168, 0.8)));
  background: -webkit-linear-gradient(top, #4f89af 0%, rgba(144, 164, 168, 0.8) 100%);
  background: -o-linear-gradient(top, #4f89af 0%, rgba(144, 164, 168, 0.8) 100%);
  background: -ms-linear-gradient(top, #4f89af 0%, rgba(144, 164, 168, 0.8) 100%);
  background: linear-gradient(to bottom, #4f89af 0%, rgba(144, 164, 168, 0.8) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4f89af', endColorstr='#90a4a8', GradientType=0);
}
.registration .well .widget {
  padding-bottom: 15px;
}
.registration .well .widget input {
  border: 1px solid #000;
  box-shadow: none;
  color: #000;
  -webkit-transition: all 400ms ease 0s;
  -moz-transition: all 400ms ease 0s;
  -o-transition: all 400ms ease 0s;
  transition: all 400ms ease 0s;
  padding: 8px 15px;
  border-radius: 5px;
}
.registration .well .widget input:hover {
  border: 1px solid @cl-orange;
}
.registration .input {
  border-radius: 0;

}
.registration .form-element {
  float: none;
  margin: 10px auto;
  text-align: center;
}
.registration .btn {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  display: block;
  margin: 10px auto;
  width: 292px;
  background: @cl-orange none repeat scroll 0 0;
  border: 1px solid @cl-orange;
  color: #fff;
  -webkit-transition: all 400ms ease 0s;
  -moz-transition: all 400ms ease 0s;
  -o-transition: all 400ms ease 0s;
  transition: all 400ms ease 0s;
}
.registration .btn:hover,
.registration .btn:focus,
.registration .btnactive {
  background: #000 none repeat scroll 0 0;
  border: 1px solid #000;
}
.registration .title h3 {
  color: #fff;
  text-align: center;
}
div.contact .ct_title h4 {
  margin-top: 11px;
}
.relateditems .w_item .h_thumbnail a {
  color: #ffffff;
}
.bg-full {
  background-size: 100% 100%;
}
.bg_register {
  background: #4285f4 none repeat scroll 0 0;
  border: 1px solid #4285f4;
}


.profile{
  padding: 50px 0;
  fieldset{
    padding-bottom: 10px;
  }
}
.profile-edit{
  padding: 50px 0;
  input {
    padding: 5px 15px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    border: 1px solid #aaaaaa;
    outline-color: #f2ce3e;
  }
  .chzn-container-single{
    .chzn-single {
      height: 33px;
      line-height: 31px;
      background: #ffffff;
      box-shadow: none;
      div b {
        margin-top: 2px;
      }
    }
  }
  .form-group.form-actions{
    padding-top: 10px;
  }
  .btn.btn-primary{
    background: @cl-orange;
    border-color: @cl-orange;
    .transition(200ms);
    &:hover{
      background: #000000;
      border-color: #000000;
    }
  }
}
.sv-image{
  .layout-z0{
    .fade-in;
    -webkit-animation-delay: 150ms;
    -moz-animation-delay:150ms;
    animation-delay: 150ms;
  }

}


.tz_thumbnail.thumbnail_home{
  h3.tz_meetup_name{
    margin-bottom: 20px;
  }

  .tz_text_hover_speaker{
    transform: translateY(30px);
    background: @cl-orange;
    color: #ffffff;
  }
  &:hover{
    .tz_text_hover_speaker{
      transform: translateY(0);
      opacity: 1;
      .transition(all 0.4s ease 500ms);
    }
  }
}
#tz-register-wrapper.bg_lass::before{
  background-color: rgba(9, 9, 31, 0.6);

}
#tz-register-wrapper{
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.zumion figure img{
  height: 250px;
}

.form-control:focus {

  outline: 0;
  box-shadow: none;
  border: 1px solid @cl-orange;

}
.bg_img_resposiver{
  display: none;
}
.TzInformation{
  .row{
    margin: auto;
  }
}
.desceription{
  color: #7e8394;
  font-size: 16px;
  font-weight: 500;
  padding: 10px 30px;
}
.item-page{
  dt.published{
    border-right: 1px solid #95979d;
    color: #95979d;
    display: inline-block;
    font-weight: 300;
    line-height: 14px;
    padding-right: 10px;
  }
  .Info_article .article-info dd{
    padding-right: 10px;
    &:before{
      transform: rotate(23deg);
      -ms-transform: rotate(23deg);
      -webkit-transform: rotate(23deg);
    }
  }
  .pager.pagenav{
    li{
      a{
        background: transparent;
        color: #22262b;
        border-color: transparent;
        padding: 0;
        font-family: Raleway,sans-serif;
        font-size: 11px;
        font-weight: 600;
        margin: 0 6px;
        text-transform: uppercase;
      }
    }
  }
  .previous{
    a:before{
      content: "\f177";
      font-size: 10px;
      padding: 8px 0;
      font-weight: 400;
      font-family: fontawesome;

    }
  }
  .next{
    a:after{
      content: "\f178";
      font-size: 10px;
      padding: 8px 0;
      font-weight: 400;
      font-family: fontawesome;
    }
  }
}
li.dropdown:hover{
  .dropdown-menu {
    opacity: 1;
    top: 98%;
    visibility: visible;
  }
}
.dropdown-menu{
  box-shadow: none;
  top: 120%;
  opacity: 0;
  transition: all 0.3s ease-in-out 0s;
  visibility: hidden;
  position: absolute;
  border-radius: 0;
}
.plazart-megamenu .mega .mega-dropdown-menu{
  display: block !important;
}
.plazart-megamenu .mega .mega-dropdown-menu ul li a:hover{
  background: rgba(0, 0, 0, 0);
}
.contact-street,.tz-telephone{
  line-height: 31px;
}
.tz-telephone{
  p{
    margin: 0;
  }
}
p.contact-telephone{
  margin: 0;
}
.smoke.smoke1{
  padding: 19px 0 0;
}
.smoke img{
 margin: 0 0 10px;
}
blockquote{
  border-left: 5px solid #eeeeee;
  margin: 0 0 20px;
  padding: 1px 15px;
}

.comment-anchor{
  display:  none;
}
.comment-author{
  display: block;
}

.tz_meetup_video_text{
  .tz_btn_3{
    margin-right: 10px;
  }
}
.tz_meetup_breadcrumb{
  .tz_meetup_breadcrumb_content{
    margin-bottom: 0;
    margin-top: 5px;
  }
}

.sc_contact{
  color: #242732;
  font-family: Raleway,sans-serif;
  font-weight: 700;
  line-height: 1.4em;
  margin-top: 0;
  font-size: 24px;
}
.right_breadcrumbs{
  .active{
    font-weight: 400;
  }
}
.item-page ul.pager.pagenav{
  border-top: 1px solid #dce2ed;
  clear: both;
  display: block;
  margin: 0;
  padding: 46px 0 0;
}
.latest_tweets ul{
  padding: 0;
}
.a_search{
  .searchid.open{
    display: block;
  }
  .searchid{
    display: none;
  }
  position: absolute;
    top: 0;
    left: 0;
  input{
    width: 100%;
    padding: 0 7px;
    z-index: 10;
    font-size: 20px;
    color: #222;
    line-height: 30px;
    font-weight: 300;
  }

}
.home-revo{
  .a_search{
    .tz-module{
      position: fixed;
      width: 100%;
      float: left;
      left: 0;
      background: #fff;
    }
  }
  .module-inner{
    max-width: 1170px;
    margin: 0px auto;
    position: relative;
  }
}

.tz-form-close{
  cursor: pointer;
  z-index: 12;
  position: absolute;
  right: 30px;
  top: 41%;
  color: @cl-orange;
}
.incon_open{
  cursor: pointer;
  &:before{
    content: "\f002";
  }

}

.search_icon{
  margin: 35px 0 0 15px;
}
.jsn-master .jsn-bootstrap input[type="text"], .jsn-master .jsn-bootstrap input[type="password"], .jsn-master .jsn-bootstrap input[type="number"], .jsn-master .jsn-bootstrap input[type="search"], .jsn-master .jsn-bootstrap input[type="email"], .jsn-master .jsn-bootstrap input[type="url"]{
  box-sizing: inherit !important;
}
.zumion_info{
  h2{
    color: #ffffff;
  }
}

.blog .article-info dt, .blog .article-info .comments-link, .blog .article-info dd{
  font-style: normal;
}
.menu-onepage{
  ul{
    margin-right: -15px;
  }
}
.search_iconres{
  display: none;
  opacity: 0;
}
.jsn-form-submit.meetup_btn{
  padding: 14px 35px;
}
.padding-tb-140px{
  padding: 140px 0;
}
.padding-tb-110{
padding: 110px 0 70px 0;
}

html{
  overflow-y: visible;
}
.overflow{
  overflow: hidden;
}
.bx-wrapper {
  background: rgba(0, 0, 0, 0.7) none repeat scroll 0 0;
  height: 100%;
  padding: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 8;
}
.hikashop_subcontainer.thumbnail{
  height: auto !important;
  padding: 0;
  margin: 0;
  border-radius: 0 !important;
}
.content_shop{
  border-top: 1px solid #e1e1e1;
  padding: 20px 10px 28px;
  .hikashop_product_name{
    text-align: left !important;
    padding-bottom: 18px;
    position: relative;
    margin: 0;
    &:before {
      position: absolute;
      content: '';
      width: 100%;
      height: 3px;
      background: #ddd;
      bottom: 0;
      left: 0;
    }
  }
  .hikashop_cart_input_button{
    color: #111 !important;
    border-color: transparent;
    border-radius: 0;
    border: 0;
    background: transparent;
    padding: 0;
    margin: 0;
  }
}

.addtocart_button,span.hikashop_product_price_full{
  width: 49%;
  display: inline-block;
  clear: both;
  position: relative;
}
span.hikashop_product_price_full{
  text-align: left !important;
}
.addtocart_button{
  text-align: right !important;
  input.hikashop_cart_input_button{
    background: 0 0;
    border: none;
    box-shadow: none;
    color: #111;
    font-size: 12px;
    font-weight: 400;
    margin: 0;
    padding: 0;
    text-transform: uppercase;
  }
}
.pricevscart{
  display: block;
  padding-top: 17px;
  width: 100%;
}
span.hikashop_product_price{
  color: #e45914!important;
  font-style: normal;
  font-size: 16px!important;
  font-weight: 400;
  line-height: 16px;
}
.hikashop_product_name a{
  color: #666;

  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  text-decoration: none;
}
.hikashop_listing_img_title{
  position: relative;
}
.tz_onsale{
  display: inline-block;
  width: 68px;
  height: 68px;
  background: none repeat scroll 0 0 #e45914;
  border-radius: 50%;
  text-align: center;
  padding: 18px 10px;
  font-family: 'Roboto Condensed',sans-serif;
  font-weight: 400;
  color: #fff;
  text-transform: uppercase;
  line-height: 16px;
  position: absolute;
  top: 33px;
  left: -8px;
  z-index: 1;
  &:after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #e45914;
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
  }
}
.tz_of_stock{
  display: inline-block;
  width: 68px;
  height: 68px;
  background: none repeat scroll 0 0 #666;
  border-radius: 50%;
  text-align: center;
  padding: 18px 10px;

  font-size: 14px;
  font-weight: 400;
  color: #fff;
  text-transform: uppercase;
  line-height: 16px;
  position: absolute;
  top: 33px;
  right: -8px;
  z-index: 1;
  &:after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #666;
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
  }
}
.pagination > li > a, .pagination > li > span,.hikashop_products_pagination .list-footer .pagenav, .hikashop_subcategories_pagination .list-footer .pagenav{
  padding: 7px 15px;
  line-height: 1.42857143;
}
.hikashop_products_pagination {
  padding: 0;
  margin-top: 100px;
  .list-footer .pagenav{
    color: #e45914;
    border: 0;
    font-weight: 400;

  }

  .pagination-list{
    a{
      font-size:  14px;
      font-weight: 400;
    }

  }
  .pagination{
    margin: 0;
    li{
      border: 1px solid #d3ced2;
      display: inline-block;
      border-right: 0;
      &:last-child{
        border-right: 1px solid #d3ced2;
      }
      a{
        margin: 0;
        border-radius: 0;
        border: 0;

      }
    }
    > .active{
      > a{
        background: #ebe9eb;
        color: #8a7e88;
        border-color: #d3ced2;
      }
    }
  }

}

.hikashop_products_listing{
  .limit{
    display: none;
  }
}
.hikashop_container{
  margin: 0 !important;
  .hikashop_subcontainer{
    padding-bottom: 25px;
    margin-bottom: 30px;
  }
}
.hikashop_product_image{
  position: relative;
  overflow: hidden;
  &:hover{
    div.view_detail{
      a{
        bottom: 15px;
      }
    }
  }

}
span.hikashop_product_price_before_discount{
  opacity: 0.5;
  color: #e45914!important;
  text-decoration: line-through;
  line-height: 16px;
  padding-right: 5px;
  p{
    font-size: 14px;
    color: #000;
    display: inherit;
    font-weight: 400;
  }
}

div.view_detail{
  a{
    background: #e45914 none repeat scroll 0 0;
    bottom: -40px;
    color: #ffffff;
    display: inline-block;
    font-family: "Roboto Condensed",sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    padding: 8px 17px;
    position: absolute;
    right: 15px;
    text-align: center;
    text-transform: uppercase;
    transition: all 0.3s ease-in 0s;
    z-index: 1;
    &:after{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 1px solid #e45914;
      transform: scaleX(1.067) scaleY(1.25);
      -webkit-transform: scaleX(1.067) scaleY(1.25);
      -moz-transform: scaleX(1.067) scaleY(1.25);
      -ms-transform: scaleX(1.067) scaleY(1.25);
      -o-transform: scaleX(1.067) scaleY(1.25);
    }
  }
}
.view_detail{

}

//list layour _+________________________________________________________________________________________________________

.layout_list{
  .tz_of_stock{
    right: auto;
    left: -8px;
  }
  ul{
    > li.hikashop_product{
      width: 100% !important;
    }
  }
  .hikashop_add_wishlist{
    padding-top: 15px;
  }
  .thumbnail div.hikashop_product_image_subdiv{
    display: inline-block;
    width: auto !important;
  }
  .hikashop_listing_img_title,.hikashop_subcontainer,.hikashop_container{
    display: block;
    float: left;
    width: 100%;
  }
  .hikashop_product_image,.content_shop{
    display: inline-block;
    float: left;
  }
  .hikashop_product_image{
    width: 25%;
  }
  .content_shop{
    width: 75%;
  }
  .hk-rating{
    display: inline-block !important;
  }
  div.hikashop_subcontainer,div.hikashop_subcontainer span{
    text-align: left !important;
  }
  .addtocart_button, span.hikashop_product_price_full{
    display: block;
    width: auto;
  }
  .addtocart_button input.hikashop_cart_input_button{
    background: #666666 none repeat scroll 0 0;
    border-radius: inherit;
    color: #ffffff !important;
    display: inline-block;

    padding: 7px 17px;
    position: relative;
    text-align: center;
    text-shadow: none;
    z-index: 1;

  }
  .tz_add_to_cart{
    display: inline-block;
    position: relative;
    margin: 0 10px 0 0;
    span{
      &:after{
        border: 1px solid #666666;
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        transform: scaleX(1.06) scaleY(1.21);
        width: 100%;
      }
    }
  }
  .view_detail_list{
    position: relative;
    a.tzdetail-view_list{
      &:after{
        border: 1px solid #e45914;
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        transform: scaleX(1.06) scaleY(1.21);
        width: 100%;
      }
      background: #e45914 none repeat scroll 0 0;
      color: #ffffff;
      display: inline-block;
      font-family: "Roboto Condensed",sans-serif;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      padding: 8px 17px;

      text-align: center;
      text-transform: uppercase;
      transition: all 0.3s ease-in 0s;
      z-index: 1;
      bottom: 0;
      cursor: pointer;
      position: relative;
      right: 0;
    }
  }
  .content_shop{
    border: 0;
    .hikashop_product_name:before{
      content: none;
    }
  }
 .view_detail_list{
   display: inline-block;
   .tzdetail-view_list{
     display: inline-block !important;
   }
 }
  .hikashop_container .hikashop_subcontainer{
    padding: 30px 0;
    border: 0;
  }
  .hikashop_product_name a{
    font-size: 16px;
    color: #111;
  }
  .content_shop .hikashop_product_name{
    padding-bottom:15px ;
  }
  span.hikashop_product_price{
    font-size: 20px;
  }
  .pricevscart{
    padding-top: 0;
    .hikashop_product_price_full{
      padding-bottom: 23px;
    }
  }
}

///display_none production hikashop
.hikashop_products{
  .hikashop_product_price_per_unit,.hikashop_product_price_per_unit_x,table.quantity,.hikashop_product_stock_count,.hk-rating,.view_detail_list .tzdetail-view_list,#hikashop_add_wishlist{
    display: none;
  }
}
//
ul.thumbnails{
  padding: 0;
}

// cart_module
.hikashop_cart_module{
  text-align: right;

  .hikashop_cart_title{
    border-bottom: 0;
    display: none;
  }
  .hikashop_cart.active {
    top: 100%;
    opacity: 1;
    visibility: visible;
  }
  .hikashop_cart {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    top: 120%;
    min-width: 430px;
    left: auto;
    right: 0;
    -webkit-transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    -o-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
    background: #ffffff;
    -webkit-box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
    border-top: 3px solid #222222;
    padding: 30px;
    z-index: 1;
  }
  tbody{
    td{
      padding: 15px 15px 15px 0;
      border-bottom: 1px solid #ededed;
      vertical-align: top;
      &.hikashop_cart_module_product_image {
        padding-top: 0;
      }
    }
  }
  .tz_icon_cart{
    font-size: 30px;
    color: #666;
    &.active{
      color: @cl-orange;
      i{
        &:before{
          content: "\f05c";
        }
      }
    }
  }

}
.hikashop_filter_module{
  .hikashop_filter_title{
    display: none;
  }
  .hikashop_filter_main{
    br{
      display: none;
    }
  }
  .hikashop_filter_main_div{
    position: relative;
  }
  .hikashop_filter_button_outside{
    position: absolute;
    top: 5px;
    right: -10px;

  }
  .filter_values_container{
    input{
      width: 100%;
      line-height: 30px;
    }
  }
  .hikashop_filter_main{
    min-height: inherit!important;
  }
}
.properties{//tinh chat
  position: relative;
  &:before{
    position: absolute;
    top: 30%;
    right: 50%;
    pointer-events: none;
  }
  input{
    margin: 0;
    color: transparent!important;
    padding-right: 0;
    &:hover{
      border: 0;
      background: transparent;
    }
  }
}
.orderby{
  .hikashop_filter_main{
    width: auto !important;
  }

}



.item-shoptz{
  .tz_top{
    padding-bottom: 45px;
    display: block;
    clear: both;
    float: left;
    width: 100%;
    .hikashop_results_counter{
      font-weight: 400;
      line-height: 2.5em;
      margin: 0;
    }
  }
  .hikashop_filter_module{
    display: inline-block;
    float: left;
    margin-right: 25px;
  }
  .pull-left{
    float: none !important;
  }
  .filter_values_container{
    select{
      padding: 10px;
      border: 1px solid #ddd;
      color: #666;
      font-weight: 400;

      text-transform: uppercase;
      background-image: url(../../../images/bg_down.png);//erro img
      background-position: 95% 50%;
      background-repeat: no-repeat;
      -moz-appearance: none;
      -webkit-appearance: none;


    }
  }
  border-top: 1px solid #ddd;
  padding-top: 50px;
}
.hikashop_product_price_with_min_qty{
  color: @cl-orange !important;
}
.view_list{
  i{
    font-size: 18px;
    color: #666;
    line-height: 1.5em;
    padding: 0 5px;
  }
  label{
    color: #666;
    float: left;
    font-weight: 400;
    text-transform: uppercase;
    margin: 0;
  }

}
.hikashop_cart_module_product_image{
  img.hikashop_product_cart_image{
    width: 100%;
  }
}
#hikashop_product_top_part,.hikashop_product_right_part{
  h1{
    border: 0;
    margin-bottom: 5px;
  }
  .hikashop_product_name_main{
    font-weight: 400;
    font-size: 26px;
    color: #111;
    display: block;
  }
  .hikashop_product_code_main{
    display: none;
  }
}
.hikashop_product_price_main{
  span.hikashop_product_price{
    font-size: 24px !important;
    font-weight: 400;
    line-height: 28px;
  }
}

.layout-show{
  .hiden-product,.view_detail_list{
    display: none;
  }
  .hikashop_product_price_per_unit{
    display: none;
  }
  .hikashop_product_price_full{
    margin: 10px 0 0;
  }

  #hikashop_product_characteristics.hikashop_product_characteristics{
    border: 0;
    padding:0;
  }
  .tz_product_characteristics{
    padding: 5px 0;
    padding-bottom: 29px !important;
    border-bottom: 1px dashed #ddd !important;
    margin-bottom: 24px;
  }
  .hikashop_vote_stars{
    margin-top: -7px;
  }
  .hikashop_product_right_part{
    width: 50%;
  }
  .hikashop_product_characteristics{
    padding-bottom: 0 !important;
    border: 0;
    margin: 0;
  }


  table.quantity{
    margin-top: -3px;
    position: relative;
    input{
      line-height: 30px;
      width: 30px;
    }
    .hikashop_product_quantity_field_change_plus{
      position: absolute;
      top: 5px;
      right: 2px;
      padding: 0;
      margin: 0;
      line-height: 10px;
      .transfrom(200ms);
      opacity: 0;
      &:hover{
        color: @cl-orange;
      }
    }
    &:hover{
      .hikashop_product_quantity_field_change_minus,.hikashop_product_quantity_field_change_plus{
          opacity: 1;
      }
    }
    .hikashop_main_image_div{
      float: left;
    }
    .hikashop_product_quantity_field_change_minus{
      position: absolute;
      bottom: 5px;
      right: 2px;
      padding: 0;
      margin: 0;
      line-height: 10px;
      .transfrom(200ms);
      opacity: 0;
      &:hover{
        color: @cl-orange;
      }
    }
  }

  .hikashop_product_price_with_min_qty{
    .hikashop_product_discount{
      color: #777777;
      font-size: 19px;
      font-weight: 400;
      line-height: 28px;
    }
  }
  .hikashop_product_right_part{
    position: relative;
    .hikashop_product_stock_count{
      font-size: 14px;
      font-weight: 400;
      color: #656565;
      line-height: 22px;
      padding-bottom: 28px;
      display: block;
    }

    .hikashop_product_discount{
      padding-right: 5px;
    }
    .hikashop_product_price_per_unit_x{
      display: block;
      color: #383838;
      font-size: 12px;
      font-weight: 400;
      line-height: 24px;
    }
    .tz_add_to_cart{
      display: inline-block;
      float: left;
      clear: both;
      padding-right: 15px;
      span{
        position: relative;
        display: inline-block;
        input{
          font-size: 12px;
          font-weight: 400;
          line-height: 12px;
          color: #fff !important;
          text-transform: uppercase;
          text-shadow: none;
          border: none;
          border-radius: 0;
          margin: 0 !important;
          padding: 9px 12px;
          margin-right: 18px;
          background: #454545;
          position: relative;
          box-shadow: none;
          margin-left: 4px;
          z-index: 1;
        }
        &:after{
          content: '';
          z-index: 0;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border: 1px solid #454545;
          transform: scaleX(1.067) scaleY(1.23);
          -webkit-transform: scaleX(1.067) scaleY(1.23);
          -moz-transform: scaleX(1.067) scaleY(1.23);
          -ms-transform: scaleX(1.067) scaleY(1.23);
          -o-transform: scaleX(1.067) scaleY(1.23);
        }
      }
    }
    .tz_onsale{
      left: -85px;
      top: 0;
    }
  }

  .hikashop_previous_product,.hikashop_next_product{
    position: absolute;
    top: -20px;
    font-family: fontawesome;
    width: 22px;
    height: 22px;
    border: 1px solid #666;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    line-height: 22px;
    background: transparent;
    background-image: none;
    color: #666;
    .transition(200ms);
    &:hover{
      color: #fff;
      background-color: #666 !important;
    }
    &:before {
      display: block;
      line-height: 10px;
      text-align: center;
    }
  }
  .hikashop_product_page{
    position: relative;
  }
  .hikashop_next_product{
    right: 0;
    &:before{
      content: "\f105";
    }
  }
  .hikashop_previous_product{
    right: 25px;
    &:before {
      content: "\f104";

    }
  }
  .hikashop_tabs_ul{
    padding: 0;
    li.hikashop_tabs_li_selected{
      background-color: @cl-orange !important;
      position: relative;
      color: #fff !important;
      &::after{
        border: none;
        background: 0 0;
        border-left: 10px solid rgba(0,0,0,0);
        border-right: 10px solid rgba(0,0,0,0);
        border-top: 8px solid #e45914;
        content: "";
        width: 20px;
        left: 50%;
        margin-left: -10px;
        position: absolute;
        top: 100%;
      }
    }
   li{
     background-color: #000 !important;
     color: #ffffff;
     font-size: 14px;
     text-transform: uppercase;
     font-weight: 400!important;
     line-height: 20px!important;
     padding: 11px 37px!important;
     margin: 0 -2px;
     .transition(200ms);
   }
  }
   .hikashop_results_counter,.view_list{
    display: none;
  }
  .hikashop_category_information h2{
    border-bottom: 0;
    text-align: center;
    position: relative;
    text-transform: uppercase;
    font-size: 22px;
    font-weight: 400;
    color: #111;
    margin-bottom: 35px;
    &:after{
      content: "";
      width: 100%;
      z-index: -2;
      height: 2px;
      background-color: #ddd;
      right: 0;
      top: 50%;
      display: block;
      position: absolute;

    }
    &:before{
      content: "";
      width: 250px;
      z-index: -1;
      margin-left: -125px;
      background-color: #fff;
      left: 50%;
      height: 2px;
      top: 50%;
      display: block;
      position: absolute;

    }
  }
  .tz_top{
    display:  none;
  }
}
img.hikashop_child_image{
  height: auto;
}
.lSAction{
  display: none !important;
}
.hikashop_tabs_content{
  padding: 15px;
}
.hikashop_tabs_div{
  .hikashop_product_description_main{
    padding: 0;
  }
}
.suffix_shop{
  border-top: 3px solid @cl-orange;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  &:before{
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.7);
  }
  .breadcrumb{
    padding: 36px 0;
    line-height: 11px;
  }
}
.tz_breadcrumb_shop{
  .lnk_berum span{
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    position: relative;
    z-index: 1;
    margin: 0;
    line-height: 2em;
  }
   div.right_breadcrumbs{
    line-height: 24px;
  }
}
.tz_breadcrumb_link{
  color: #ffffff;
  p{
    margin: 0;
    text-align: center;
    line-height: 22px;
  }
}
.hikashop_footer{
  display: none;
}
.production_list{
  padding: 0 0 21px;
  margin-bottom: 110px;
  .tz_top{
   display: none;
  }

  .module-title{
    text-align: center;
    font-size: 22px;
    font-weight: 400;
    color: #111;
    text-transform: uppercase;
    margin-bottom: 30px;
    &:before{
      content: none;
    }
  }
  .hikashop_vote_stars{
    padding: 0;
  }
  .content_shop{
    padding: 0;
    border: 0;
    .hikashop_product_name:before{
      content: none;
    }
  }
  .pricevscart{
    padding: 0;
    text-align: left;
  }

  .hikashop_product_image:hover div.view_detail a,.tz_of_stock,div.view_detail a,.tz_onsale,.tz_add_to_cart{
    display: none;
  }
  //
  .content_shop{
    display: inline-block;
    float:  left;
    width: 60%;
    padding-left: 10px;
  }
  .hikashop_subcontainer,.hikashop_product_listing_image{
    float:  left;
    clear: both;
  }
  .hikashop_subcontainer{
    padding: 0;
    padding-bottom: 10px;
    border: 0;
    border-bottom: 1px solid #ddd;

  }
  .hikashop_product_image{
    display: inline-block;
    float: left;
    clear: both;
    width: 32%;
  }
  .hikashop_container .thumbnail:hover{
    box-shadow: none;
  }
  .hikashop_product_name{
    padding-bottom: 0 !important;
    a{
      font-size: 15px;
      font-weight: 400;
      color: #151515;
      text-transform: uppercase;
    }
  }
  .hikashop_product_discount{
    color: #454545;

  }
}
//checkour
.hikashop_checkout_login{
  width: auto;
}
.hikashop_checkout_login_right_part{
  fieldset{
    text-align: center;
    h3{
      display: block;
      font-weight: 400 !important;
      font-size: 22px !important;
      color: #111 !important;
      line-height: 24px;
      text-transform: uppercase;
      text-align: center;
      position: relative;
      margin: 0 0 30px;
      padding: 0 0 16px;
      &:before{
        content: '';
        width: 85px;
        height: 3px;
        background-color: #ddd;
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -43px;
      }
    }
  }
}
.hikashop_product_carousel{
  table{
    position:  relative;
    margin:0 -10px !important;
    th{
      >a{
        position: absolute;
        z-index: 2;
        i{
          color: #FFF;
          font-size: 20px;
          padding: 7px;
        }
      }
      a.buttonprev{
        position: absolute;
        top: 50%;
        margin-top: -19px;
        left: -8px;
        width: 19px;
        height: 38px;
        border: none;
        background: none repeat scroll 0 0 #e1e1e1;
        box-shadow: none;
        border-top-left-radius: 38px;
        border-bottom-left-radius: 38px;
        padding: 0;
        text-align: center;
        z-index: 1;
      }
      a.buttonnext{
        position: absolute;
        top: 50%;
        margin-top: -19px;
        right: -8px;
        width: 19px;
        height: 38px;
        border: none;
        background: none repeat scroll 0 0 #e1e1e1;
        box-shadow: none;
        border-top-right-radius: 38px;
        border-bottom-right-radius: 38px;
        padding: 0;
        text-align: center;
        z-index: 1;
      }
    }
  }
  //.hikashop_main_carousel_div{
  //  width: 800px !important;
  //  > ul{
  //    //width: 800px !important;
  //    > li{
  //      width: 800px !important;
  //      li{
  //        width: 31.333333% !important;
  //      }
  //    }
  //  }
  //}
}
.hikashop_products{
  .hikashop_product_carousel{
    .hk-rating{
      display:  block;
    }
  }
}
.hk-rating{
  a{
    float:  left;
    }

}
.hikashop_main_carousel_div{
  border: 0;
  li{
    >ul{
      >li{
        border: 1px solid #ddd;
      }
    }
  }
}
.hikashop_checkout_login{
  .hikashop_checkout_login_right_part{
    fieldset.input{
      border: 1px solid #ddd;
      padding: 10px;
    }
  }
  .control-group{
    input{
      width: 90%;
      display: inline-block;
    }

  }
  .control-label,.controls{
    select{
      width: 90%;
      display: inline-block;
    }
  }

}
.control-group{
  input.hikashop_cart_input_button{
    background: none repeat scroll 0 0 #e80f60;
    border: medium none;
    border-radius: 0;
    box-shadow: none !important;
    color: #fff !important;
    display: inline-block;
    font-family: "Roboto Condensed",sans-serif;
    font-size: 14px;
    font-weight: 400;
    padding: 13px 41px !important;
    margin: 8px 0 0 !important;
    text-shadow: none !important;
    text-transform: uppercase;
    width: auto;
  }
}
.hikashop_checkout_coupon{
  #hikashop_checkout_coupon_input{

    font-size: 14px;
    font-weight: 400;
    color: #656565;
    width: 186px;
    padding: 6px 15px 10px;
  }
  .hikashop_cart_input_button{
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #FFF !important;
    text-transform: uppercase;
    text-shadow: none !important;
    background: none repeat scroll 0 0 #454545;
    border: none;
    border-radius: 0;
    box-shadow: none !important;
    padding: 8px 22px 7px !important;
  }
}
.hikashop_checkout_cart{
  .table{
    background: none repeat scroll 0 0 #fafafa;
  }
  .hikashop_cart_product_image_thumb{
    img{
      width: 110px;
    }
  }
  tr.row0{
    &:hover{
      background: #ffffff;
    }
    background: #fff;
  }
  tr{
    th{
      padding: 7px 10px;
      margin: 0;
      font-size: 12px;
      font-weight: 400;
      text-transform: uppercase;
      color: #656565;

    }


    td{
      padding: 30px 0 !important;
      &.hikashop_cart_product_image_value{
        padding: 10px !important;
      }
      &.hikashop_cart_total_value{
        span{
          font-weight: 400;
          font-size: 16px;
          color: #484747 !important;
          text-transform: uppercase;
          font-style: normal;
        }

      }
    }
  }
  .hikashop_cart_product_name a,span.hikashop_product_price,.visible-phone{
    font-weight: 400;
    font-size: 16px;
    color: #484747 !important;
    text-transform: uppercase;
  }
  .table th.hikashop_cart_product_price_title, .table th.hikashop_cart_product_quantity_title, .table th.hikashop_cart_product_total_title{
    text-align: center;
  }
}
.hikashop_checkout_login_left_part{
  .input-small{
    padding: 10px !important;
  }
  #modlgn-remember{
    width: auto;
  }
}
.iffooter{
  .module-title{
    margin-bottom: 13px;
    &:before{
      content: none;
    }
    span{
      font-size: 18px;
      font-weight: 400;
      line-height: 22px;
      color: #000;
      text-transform: uppercase;
    }
  }
  p{
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    color: #666;
    line-height: 24px;
  }
  .nav > li > a{
    padding: 0;
  }
  .nav-stacked{
    li{
      a{
        font-weight: 400;
        font-size: 14px;
        font-style: italic;
        color: #666;
        line-height: 24px;
      }
    }
  }
  .max-email-box{
    form{
      padding-top: 21px;
    }
  }
}
body .easy-opener-content{

  transform: translateY(0);
  -webkit-transform: translateY(0);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);

}
.div.pp_default .pp_close{
  color: transparent;
}
//home_header-----------------------------------------------------------------------------------------------------------
.lo_head_style{
  &.remove-top{
    height: 0;
    padding: 0;
    overflow: hidden;

  }
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 21;
  transition: all .2s linear 0s;
  -moz-transition: all .2s linear 0s;
  -webkit-transition: all .2s linear 0s;
  -ms-transition: all .2s linear 0s;
  -o-transition: all .2s linear 0s;
  .container{
    padding-top: 7px;
    padding-bottom: 7px;
    transition: all 0.2s linear 0s;

  }
}
.tz-homeType2.tz-home-head{
  top: 48px;
  &.remove-top{
    top: 0;
  }
}
// Contact_home_header--------------------------------------------------------------------------------------------------
.TzContact_head{
  width:  75%;
  margin: 0 auto;
  padding-top: 156px;
  padding-bottom: 104px;
  color: #aaa;
  font-size: 16px;
  font-weight: 300;
  line-height: 2em;
  p{
    color: #aaa;
    font-size: 16px;
    font-weight: 300;
    line-height: 2em;
  }
  .TzContactInfo_header{
    text-align: left;

  }
}
.title_tzcontact_homeheader{
  h3{
    font-weight: 300;
    color: #e45914;
    font-size: 24px;
    margin-bottom: 0;
    text-transform: uppercase;
  }
  h4{
    text-transform: uppercase;
    color: #fff;
    font-size: 24px;
    line-height: 1.2em;
    margin-bottom: 0;
  }
}
.tzContactAbout_header,.tz_meetup_contact_conference_header,.tzContactAddress_header,.tzContactOurWeb{
  h5{
    font-weight: 700;
    color: #fff;
    font-size: 20px;
    margin: 0;
  }

}
.tzContact_2_content {
  margin-bottom: 50px;
}
// silder_revolution
.tz-sliderrevo-title{
  z-index: 9;
  white-space: nowrap;
  line-height: 20px;
  font-weight: 500;
  visibility: inherit;
  transition: none;
  border-width: 0;
  margin: 0;
  padding: 0;
  letter-spacing: 0;
  font-size: 13px;
  min-height: 0;
  min-width: 0;
  max-height: none;
  max-width: none;
  opacity: 1;
  position: relative;
  &:after{
    position: absolute;
    content: '';
    bottom: -9px;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: #e45914;
  }
}
.tp-dottedoverlay{
  background-image: none !important;
  background-color: rgba(0,0,0,.7) !important;
}
.tz-caption{

  position: relative;
  &:after{
    position: absolute;
    content: '';
    bottom: -12px;
    left: 0;
    width: 100%;
    height: 5px;
    background-color: #e45914;
  }
}
body .tp-bullets.preview1 .bullet:hover, body .tp-bullets.preview1 .bullet.selected {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background: @cl-orange !important;
  width: 13px !important;
  height: 13px !important;
  border: 3px solid @cl-orange !important;
  border-radius: 50%;
}
body{
  &.com_content{
    overflow-x: hidden !important;
  }

  .tp-bullets.preview1 .bullet {
    cursor: pointer;
    position: relative !important;
    background: transparent !important;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    border-radius: 50%;
    box-shadow: none;
    width: 13px !important;
    height: 13px !important;
    border: 3px solid @cl-orange !important;
    display: inline-block;
    margin-right: 5px !important;
    margin-bottom: 0 !important;
    -webkit-transition: background-color 0.2s, border-color 0.2s;
    -moz-transition: background-color 0.2s, border-color 0.2s;
    -o-transition: background-color 0.2s, border-color 0.2s;
    -ms-transition: background-color 0.2s, border-color 0.2s;
    transition: background-color 0.2s, border-color 0.2s;
    float: none !important;
    box-sizing: content-box;
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
  }
}
// Top_home_revo
a.tz_upcoming_event {
  display: inline-block;
  background: #fff;
  text-decoration: none;
  font-weight: 700;
  padding: 1px 10px;
  margin-right: 15px;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}
p.tz_description_event {
  display: inline-block;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 2.6em;
  margin: 0;
}

.switchToList,.switchToGrid{
  cursor: pointer;
}
.fix_memu_default{
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 21;
  background-color: rgba(255,255,255,0.7);
}
.tz-home-head{

  ul.navbar-nav > li:last-child{
    padding-right: 0;
  }
  .row{
    position: relative;
  }
}
.search_icon_revo{
  color: #ffffff;
  padding-left: 20px !important;
}
.tp-caption a:hover{
  color: #ff7302 !important;
}

