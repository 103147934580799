.typography_count{
  padding-bottom: 11px;
  margin-bottom: 23px;
  position: relative;
  &:before{
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 25px;
    height: 1px;
    background: @cl-orange;
  }
}
.smoke{
  padding: 32px 0 0;
  img{
    margin: 10px 0;
    width: 100%;
  }
  &.numeric_list{
    ol{
      li{
        list-style-type: decimal;
        padding: 5px 0;
      }
    }
  }
  &.lists{
    ul{
      li{
        list-style-type: disc;
        padding: 5px 0;
      }
    }
  }
}
blockquote{
  font-size: 14px;
  line-height: 22.4px;
}
code {
  background-color: rgba(228, 89, 20, 0.2);
  display: block;
  padding: 20px;
}
.typography{

    .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12{
      padding-left: 7.5px;
      padding-right: 7.5px;
    }

}
